@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:wght@100&display=swap");

@font-face {
  font-display: swap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}

.careermaindiv h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif !important;
}

a {
  font-family: "Open Sans", sans-serif;
}

p {
  font-family: "Open Sans", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.main-payment-freq {
  width: 20%;
  background: #fff;
  border-radius: 40px;
}

.inner-payment-freq {
  color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}

.active-payment-freq {
  background: red;
  color: #fff;
  padding: 10px;
  border-radius: 40px;
  cursor: pointer;
}
.inner-payment-freq p:hover {
  background: red;
  color: #fff;
  padding: 5px 10px;
  border-radius: 40px;
  cursor: pointer;
}
.inner-payment-freq p {
  padding: 5px 10px;
}
.main-payment-freq .mostPopularplain {
  background: #fce100;
  color: #000;
  top: -1px;
  font-weight: 500;
  border-radius: 40px;
  z-index: 11;
}
.plansdescriptions {
  width: 40%;
  background: #2b263491;
  color: #fff;
  border-top: 2px solid;
  border-radius: 12px 12px 0 0;
}

body::-webkit-scrollbar-thumb {
  background-color: #ff0022;
  border-radius: 3px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ff0022;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header_ajiva .active {
  color: #ec0013 !important;
}

span.phone_content {
  background: red;
  padding: 7px 20px;
  clip-path: polygon(25% 0%, 100% 0, 75% 100%, 0% 100%);
}

ul.thumbs.animated {
  display: none;
}

.carousel .control-dots .dot {
  background: #ec0013;
  width: 28px;
  height: 28px;
}

.our_services_card a {
  display: flex;
  align-items: center;
  z-index: 2;
}

.aj_ensure_customers_bg {
  /* background-image: url(./img/background_img.webp); */
  width: 100%;
  background-position: 100% 100%;
  /* background-repeat: no-repeat; */
}

.hide_content {
  width: 400px;
  height: 67px;
  overflow: hidden;
}

.blog_overflow_content {
  height: 670px;
  overflow: auto;
}

/* .aj_ensure_customers_content {
  top: 0px;
  background-color: #282c34;
  height: 490px;
  left: 0;
  border-top: 5px solid #ec0013;
  
} */
.wp-matinance-payment-frequency {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 7px 0px;
  font-size: 14px;
}
.wp-matinance-service-frequency {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 28px;
  margin: 8px 0px;
  align-items: center;
}
.wp-matinance-service-covers {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 28px;
  align-items: center;
}
.Maintenance-Services {
  font-size: 3rem;
}
.card-heading select option {
  background: none;
  color: #000;
}

.aj_ensure_customers_content_info {
  top: 0px;
  background-color: #282c34;
  height: 490px;
  left: 0;
  border-top: 5px solid #ec0013;
}
.aj_clip_img {
  background-color: #fff;
  clip-path: polygon(15% 0, 100% 0, 100% 93%, 0 93%);
  display: flex;
  float: right;
  justify-content: center;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  right: 0;
  top: 0;
  height: 500px;
  text-align: center !important;
}

.aj_ensure_customers_img img {
  width: 100%;
  padding: 33px;
  margin-left: 0px;
}

.data_management img {
  height: 413px;
  object-fit: cover;
  width: 100%;
}

.aj_data_circle {
  background-color: red;
  display: flex;
  text-align: center;
  top: 38%;
  /* left: 45%; */
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 110px;
  color: #fff;
  font-weight: bold;
  border: 5px solid #fff;
  font-size: 16px;
  width: 150px;
  height: 150px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.aj_about_ajiva {
  background-image: url(./img/about_ajiva_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.staff_member ul.control-dots {
  display: none;
}

.footer_bg_images {
  background-image: url(./img/background_img/Footer.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.js_our-services_content .carousel .control-dots .dot {
  background: #ec0013;
  width: 70px !important;
  height: 5px !important;
  border-radius: 1px !important;
}

.CircularProgressbar {
  width: 70% !important;
  height: 70% !important;
}

.icon img {
  position: absolute;
  bottom: 25px;
  left: 15px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #ed112b !important;
}

.animate__animated .animate__fadeInUpBig {
  --animate-duration: 8s;
}

.our_services_card {
  border-radius: 4px;
}

.js_slider_one .carousel .control-dots .dot {
  background: #ec0013;
  width: 14px;
  height: 14px;
}

.js_slider_one .carousel .control-dots {
  margin: 0px 0;
  padding: 10px 0px;
  border-bottom: 10px solid #333333;
}

.staff_member {
  border-top: 1px solid #828282;
  border-bottom: 1px solid #828282;
}

.staff_member.carousel.carousel-slider .control-arrow:hover {
  background: rgb(0 0 0 / 0%);
}

.staff_member .carousel .control-next.control-arrow:before {
  border-left: 15px solid #ff0000;
  opacity: 1;
}

.staff_member .carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 15px solid #ffffff00;
  border-bottom: 15px solid #fff5f500;
  content: "";
}

.staff_member.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #ff0c0c;
  opacity: 1;
}

.staff_member.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  content: "";
}

.aj_about_bg {
  background-image: url(./img/js_about_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300pt;
}

.aj_contact_bg {
  background-image: url(./img/contact_bg_img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.aj_blog_bg {
  background-image: url(./img/blog_bg_img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.js_about-img-wrap {
  display: flex;
  position: relative;
  z-index: 1;
}

.js_about-img-wrap .shape_one {
  position: absolute;
  left: 50px;
  bottom: -50px;
  z-index: 1;
}

.js_about-img-wrap .js_about_img {
  overflow: hidden;
  z-index: 2;
}

.pdf_align {
  display: flex;
  align-items: center;
}

.js_about-img-wrap .js_about_img img {
  border-radius: 10px;
  width: 90%;
}

.js_about-img-wrap .js_about_img2 img {
  padding-left: 0;
  width: 90%;
  border-radius: 10px;
  margin-top: 85px;
}

.js_about_img2 {
  margin-left: 0px;
  z-index: 3;
}

.js_counter_company {
  background-color: #282c34;
  position: relative;
}

.aj_portfolio_bg {
  background-image: url(./img/portfolio_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.aj_services_bg {
  background-image: url(./img/background_img/Services.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.blog_card {
  display: flex;
  /* align-items: center; */
}

.modal-details-fade {
  position: fixed;
  overflow: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 60;
  background: #000000eb;
  padding: 6%;
}

.closemodal {
  position: absolute;
  top: -13px !important;
  right: -10px;
  cursor: pointer;
  background: #ffff;
  border-radius: 50px;
  border: 1px solid #22374e;
  width: 30px;
  padding: 0 6px 0 6px;
  height: 30px;
}

.contactcard {
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 0px 2px 2px;
  background: #fff;
}

textarea:focus-visible {
  outline: 1px solid crimson;
  border-radius: 3px;
}

input:focus-visible {
  outline: 1px solid crimson;
  border-radius: 3px;
}

.card4 {
  display: block;
  top: 0px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 35px;
  text-decoration: none;
  transition: all 0.5s;
  overflow: hidden;
  border: 1px solid #cccccc;
  border-right-color: red;
  border-right-width: 2px;
  padding-bottom: 18px;
}

.aj_btn_get_start {
  position: relative;
  box-shadow:
    rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: 1px solid #a3a3a3;
  transition: 0.8s;
}

.aj_btn_get_start:hover {
  border: 1px solid red;
  transition: 0.5s;
  box-shadow:
    rgba(247, 108, 108, 0.02) 0px 1px 3px 0px,
    rgba(255, 116, 116, 0.15) 0px 0px 0px 1px;
}

.aj_btn_get_start:hover:after {
  transition: 0.5s;
  color: #fff;
}

.aj_btn_get_start:after {
  content: "\2511";
  color: red;
  position: absolute;
  top: 0;
  right: 0;
}

.go-corner {
  background-color: #00838d;
  height: 100%;
  width: 38px;
  /* width: 24px; */
  padding-right: 9px;
  right: -2px;
  top: 0;
  border-radius: 0;
  /* transform: skew(6deg); */
  margin-right: -36px;
  transition: all 0.5s;
  position: absolute;
  align-items: start;
  background-image: linear-gradient(-45deg, #ed112b 1%, #ed112b 100%);
}

.go-arrow {
  transform: skew(-6deg);
  margin-left: -2px;
  margin-top: 9px;
  opacity: 0;
  transition: all 0.5s;
}

/* .card4:hover .go-arrow {
  border: 1px solid #cd3d73;
} */
.card4:hover {
  border: 1px solid transparent;
  border: 1px solid #cccccc;
  transition: all 0.5s;
  box-shadow: none;
}

/* .card4:hover i {
  display: none;
} */
.none_cantent {
  display: none;
}

h3 {
  margin-top: 8px;
}

.card4 {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -6px;
}

.card4:hover .go-corner {
  margin-right: -16px;
  transition: all 0.5s;
}

.card4:hover .go-arrow {
  color: #fff;
  margin: 5px 3px;
  transition: all 0.5s;
  opacity: 1;
}

#nav-icon3 {
  width: 40px;
  height: 45px;
  position: relative;
  margin: 0px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.icon {
  display: none;
}

.our_services_card .icon {
  display: block;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #808080;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 7px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 15px;
}

#nav-icon3 span:nth-child(3) {
  top: 15px;
}

#nav-icon3 span:nth-child(4) {
  top: 23px;
}

#nav-icon3.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.modal-details {
  width: 850px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  border-radius: 7px;
  padding: 20px 10px 10px 10px;
  position: relative;
  border-bottom: 5px solid #ed112b;
}

.aj_text_line {
  width: 100%;
  overflow: hidden;
  height: 100px;
  text-overflow: initial;
  white-space: pre-wrap;
}

.card4 p {
  width: 100%;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 77px;
  font-size: 13px;
}

.porfolio_company_logo {
  width: 80px;
  align-items: center;
  display: flex;
  height: 80px;
}

.porfolio_company_logo img {
  width: 100%;
  transition: 0.5s;
  /* object-fit: cover; */
}

.thrre_dot_logo:hover svg {
  fill: #fff;
}

.aj_GFA_Life {
  position: relative;
  background-color: red;
  height: 50px;
  width: auto;
  border: 1px solid red;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.aj_GFA_Life_second {
  position: absolute;
  top: -10%;
  left: 19%;
  transform: translate(-50%, -50%);
  height: 110px;
  width: 147px;
  border-radius: 150px 150px 0 0;
  background-color: #ff0014;
}

.aj_circle_gfa {
  position: absolute;
  left: 8.9%;
  border-radius: 50%;
  padding: 50px 25px;
  background-color: #fff;
  top: -46px;
}

.aj_circle_gfa span {
  font-size: 22px;
  border-bottom: 2px solid #ff0014;
  /* margin-bottom: 10px !important; */
  color: #030303;
  /* /* text-decoration: underline; */
}

.aj_circle_gfa .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 138px;
  height: 96px;
  background-color: #f3f3f3;
  border-radius: 0 0 500px 0;
  content: "";
}

.aj_GFA_Life:before {
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 0;
  width: 206px;
  height: 81px;
  background-color: #f3f3f3;
  border-radius: 0 0 500px 0;
  content: "";
}

.image-box.flex {
  justify-content: end;
  /* margin: 0px 80px; */
  padding: 30px 0px;
}

.rating {
  margin: 0px 20px;
}

.slide_gfa .text {
  padding: 15px;
}

.slide_gfa {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* padding: 20px; */
}

/*  */
.owl-item {
  width: 566.25px;
  margin-right: 30px;
}

.testimonial-block {
  position: relative;
  padding: 60px 0 0;
}

.testimonial-block .inner-box {
  position: relative;
  background-color: #fff;
  border-top: 25px solid #da0006;
  padding: 30px 0px 23px 33px;
  margin-bottom: 30px;
  border-radius: 10px;
  height: 270px;
}

.testimonial-block .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 192px;
  height: 66px;
  background-color: #efefef;
  border-radius: 0 0 500px 0;
  content: "";
}

.testimonial-block .image-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: end;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  /* padding-left: 140px; */
  margin-bottom: 25px;
}

.testimonial-block .image-box .image {
  position: absolute;
  left: 26px;
  top: -100px;
  margin-bottom: 0;
  height: 132px;
  width: 132px;
  padding: 12px;
}

.testimonial-block .image-box .image:before {
  position: absolute;
  left: 0;
  top: -3px;
  height: 50%;
  width: 100%;
  background-color: #da0006;
  border-radius: 120px 120px 0 0;
  content: "";
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.testimonial-block .image-box .image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border: 5px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  z-index: 12;
  position: relative;
  transition: all 300ms ease;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
}

.testimonial-block .name {
  margin-bottom: 2px;
}

.testimonial-block .designation {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 26px;
  color: #6a6a6a;
  font-weight: 400;
}

.testimonial-block .rating {
  position: relative;
  color: #fee300;
  font-size: 14px;
  letter-spacing: 0.05em;
}

.text {
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

/*  */

/**************************************** animation background home Start********************************* */
.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

/* .area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
} */

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgb(151 149 149 / 35%);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/**************************************** animation background home End********************************* */
/****************************************************** animation */
.reveal {
  position: relative;
  /* opacity: 0; */
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.react-tel-input .form-control {
  padding-left: 11% !important;
}
/****************************************************************End******************  */
.dec_cirlce {
  position: absolute;
  width: 240px;
  z-index: 0;
  top: 20%;
  right: -160px;
  height: 240px;
}

.dec_cirlce:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0px 0px 0px 86px #fbfbfb;
  animation: sharedec_rotate 5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  border: 2px dotted rgb(0 0 0 / 5%);
}

.dec_cirlce span {
  border-top-color: #ff6220 !important;
  border-bottom-color: #ff6220 !important;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: 2;
  background: #f9f9f9;
  margin: -40px 0 0 -40px;
  animation: sharedec_rotate2 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  border: 2px solid rgb(0 0 0 / 5%);
}

.dec_cirlce:before {
  border-left-color: #2046ff;
  border-right-color: #2046ff;
}

.dec_cirlce2 {
  position: absolute;
  width: 210px;
  z-index: 0;
  top: -10%;
  left: -98px;
  height: 210px;
}

.dec_cirlce2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: 1;
  /* box-shadow: 0px 0px 0px 86px #fbfbfb; */
  animation: sharedec_rotate 5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  border: 2px dotted rgb(0 0 0 / 5%);
}

.dec_cirlce2 span {
  border-top-color: #ed112b !important;
  border-bottom-color: #ed112b !important;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: 2;
  /* background: #f9f9f9; */
  margin: -40px 0 0 -40px;
  animation: sharedec_rotate2 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  border: 2px solid rgb(0 0 0 / 5%);
}

.dec_cirlce2:before {
  border-left-color: #ed112b;
  border-right-color: #ed112b;
}

.dec_cirlce3 {
  position: absolute;
  width: 210px;
  z-index: 0;
  bottom: -9%;
  right: -104px;
  height: 210px;
}

.dec_cirlce3:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: 1;
  /* box-shadow: 0px 0px 0px 86px #fbfbfb; */
  animation: sharedec_rotate 5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  border: 2px dotted rgb(0 0 0 / 5%);
}

.dec_cirlce3 span {
  border-top-color: #ed112b !important;
  border-bottom-color: #ed112b !important;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: 2;
  /* background: #f9f9f9; */
  margin: -40px 0 0 -40px;
  animation: sharedec_rotate2 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  border: 2px solid rgb(0 0 0 / 5%);
}

.dec_cirlce3:before {
  border-left-color: #ed112b;
  border-right-color: #ed112b;
}

@keyframes sharedec_rotate {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes sharedec_rotate2 {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0);
  }
}

.home_slider .control-dots .dot {
  background: #ffffff;
  width: 18px;
  height: 18px;
  border: 2px solid red;
}

.slidercrm {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slidercrm .slide-track {
  -webkit-animation: scroll 20s linear infinite;
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slidercrm .slideimg {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slidercrm .slideimg img {
  width: 80%;
  border-radius: 10px;
}

.slidercrm .slideimg_in {
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.slidercrm .slideimg_in img {
  width: 80%;
  border-radius: 10px;
}

.slideremployee {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slideremployee .slide-track2 {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  cursor: pointer;
  width: calc(250px * 14);
}

.slideremployee .slideimg2 {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideremployee .slideimg img {
  width: 80%;
  border-radius: 10px;
}

.successmsg i {
  font-size: 140px;
}

.successmsg p {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 10px 10px 10px;
}

.successmsg {
  text-align: center;
}

#male,
#female {
  accent-color: #dc2626;
}

.successmsginner {
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding: 50px 0 50px 0;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.genderdiv {
  display: flex;
  align-items: center;
}

.careerdiv {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.carousel .control-prev.control-arrow:before {
  border-right: 16px solid #ffffff;

  opacity: 1.2;
}

.carousel .control-next.control-arrow:before {
  border-left: 16px solid #fff;
}

.bar {
  height: 5px;
  width: 90px;
  background: #f1cdcd;
  top: 1px;
  margin-left: 10px;
  position: relative;
  border-radius: 30px;
}

.bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
  0% {
    background-color: red;
    left: 0px;
    /* top: 0px; */
  }

  25% {
    left: 85px;
    right: 0px;
  }

  50% {
    left: 85px;
    right: 0px;
  }

  75% {
    left: 0px;
    right: 0px;
  }

  100% {
    background-color: red;
    right: 0px;
    /* top: 0px; */
  }
}

.notification-main {
  position: fixed;
}

.notification-inner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

/* curser css */

/* curser css End */
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.porfolio_company_logo:hover img {
  transform: scale(1.6);
  transition: 0.5s;
  overflow: hidden;
}

.ourtestimonials {
  border-top: 1px solid #828282;
  border-bottom: 1px solid #828282;
}
@media (min-width: 400px) and (max-width: 450px) {
  .aj_data_circle {
    top: 41% !important;
    font-size: 11px;
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 340px) and (max-width: 400px) {
  .aj_data_circle {
    top: 45% !important;
    font-size: 11px;
    width: 100px;
    height: 100px;
  }

  .Start_Getting_Found {
    width: 100%;
    text-align: center;
  }
  .footer_logo_211 {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .footer_logo_211 img {
    width: 45%;
  }
  .aj_ensure_customers_content_info {
    height: 470px !important;
  }
  .ecomerece_border img {
    height: 100% !important;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .aj_clip_img {
    display: none;
  }
  .ajv-API-Integration-card {
    gap: 30px;
  }
  .ecomerece_border img {
    height: auto !important;
  }
  .aj_ensure_customers_content_info {
    top: 0px;
    background-color: #282c34;
    height: 380px;
    left: 0;
    border-top: 5px solid #ec0013;
  }
  .Ensure_Customers_Find {
    font-size: 25px;
  }
  .dec_cirlce {
    position: absolute;
    width: 240px;
    z-index: 0;
    top: 34%;
    right: 162px;
    height: 240px;
  }
  .aj_data_circle {
    display: none;
  }
  .CircularProgressbar {
    width: 14% !important;
    height: 14% !important;
    margin: auto;
  }

  .benefits-section-4 p,
  .benefits-section-5 p,
  .benefits-section-6 p {
    padding: 10px 0px 10px 0px !important;
  }
  .ajv-section-right .benefits-section-4 {
    border-radius: 0px !important;
  }
  .ajv-section-right .benefits-section-5 {
    border-radius: 0px !important;
  }
  .ajv-section-right .benefits-section-6 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-2 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-1 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-3 {
    border-radius: 0px !important;
  }
  .benefits p {
    padding-right: 0px !important;
    line-height: 20px !important;
  }
  .benefits-section-4 h1,
  .benefits-section-5 h1,
  .benefits-section-6 h1 {
    padding: 0px !important;
  }
  .Integrationservices h2 {
    font-size: 23px !important;
    margin: 10px 0px;
  }
  .iconf {
    width: 100% !important;
  }
  .icon img {
    position: absolute;
    bottom: 36px;
    left: 0px;
    margin: auto;
    right: 0px;
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .Platformspecific-1 {
    left: 0px !important;
  }
  .iconf {
    width: 100% !important;
  }
  .Platformspecific-3 {
    left: 0px !important;
  }
  .Integrationservices h2 {
    font-size: 23px !important;
  }
  .benefits-section-4 p,
  .benefits-section-5 p,
  .benefits-section-6 p {
    padding: 10px 0px 10px 0px !important;
  }
  .ajv-section-right .benefits-section-4 {
    border-radius: 0px !important;
  }
  .ajv-section-right .benefits-section-5 {
    border-radius: 0px !important;
  }
  .ajv-section-right .benefits-section-6 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-2 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-1 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-3 {
    border-radius: 0px !important;
  }
  .benefits p {
    padding-right: 0px !important;
    line-height: 20px !important;
  }
  .benefits-section-4 h1,
  .benefits-section-5 h1,
  .benefits-section-6 h1 {
    padding: 0px !important;
  }
  #nav-icon3 {
    width: 28px;
    height: 25px;
    position: relative;
    margin: 0px auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    top: -4px;
  }
  .aj_data_circle {
    top: 50%;
    font-size: 11px;
    width: 100px;
    height: 100px;
  }
  .CircularProgressbar {
    width: 22% !important;
    /* left: 0; */
    /* right: 0; */
    margin: auto;
  }
  .data_management img {
    height: auto;
  }
  .commercsalution {
    text-align: justify;
    font-size: 14px;
    line-height: 1.4;
  }
  .aj_clip_img {
    display: none;
  }
  .Find_More_Business {
    font-size: 14px;
  }
  .Ensure_Customers_Find {
    font-size: 20px;
  }
  .business_owners_need {
    text-align: justify;
  }
  .aj_ensure_customers_bg {
    height: auto;
    background-size: cover;
  }
  .aj_ensure_customers_content_info {
    top: 0px;
    background-color: #282c34;
    height: 530px;
    left: 0;
    border-top: 5px solid #ec0013;
  }
  .ecomerece_border img {
    height: auto !important;
  }
  /* .cardcommercsalution {
    text-align: justify;
    font-size: 14px;
    line-height: 1.4;
  }
  .cardcommercsalutionouter {
    font-weight: 500;
  } */
}
@media (min-width: 320px) and (max-width: 768px) {
  .rating {
    margin: 0px 0px;
  }

  .store {
    width: 90%;
    margin: 0 auto;
  }

  .testimonial-block .image-box {
    position: relative;
    display: flex;
    justify-content: start;
    transition: all 300ms ease;
    padding: 5px;
    margin-bottom: 5px;
  }

  .owl-item {
    width: 100%;
    margin-right: 30px;
  }

  .testimonial-block .inner-box {
    padding: 30px 16px 5px;
  }

  .testimonial-block .image-box {
    padding-left: 0px;
  }

  .testimonial-block .inner-box:before {
    width: 146px;
    height: 74px;
  }

  .innnnnn {
    display: flex;
    margin-top: 54px;
  }

  .nav_v li {
    border-bottom: 1px solid #d7d7d7;
    padding: 10px;
    width: 100%;
  }

  .nav_v li:hover {
    border-bottom: 1px solid red;
  }

  .nav_v li:hover a {
    color: red;
  }

  .nav_v {
    display: none;
    position: absolute;
    top: 60px;
    z-index: 9;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    background: #fff;
    padding: 20px 0 20px 0;
    border-top: 3px solid #000 !important;
    border: 1px solid rgba(0, 0, 0, 0.04);
  }

  .hideRemove {
    display: block;
    margin-top: -5px;
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
    background: #eee;
  }
  .hideRemove .navbaarresponcive a {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 13px;
  }

  a.icon {
    display: block;
  }

  .blog_card {
    display: block;
  }
  .wp-matinance-service-covers {
    font-size: 18px;
  }
  .main-payment-freq {
    width: 100%;
  }
  .wp-matinance-service-frequency {
    font-size: 18px;
  }
  .plansdescriptions {
    width: 100%;
  }

  .aj_ensure_customers_content {
    height: auto;
  }

  .carousel .control-dots {
    top: 70px;
  }

  .header_top .inner-section {
    display: none;
  }
  .header_top .social_icon {
    display: none;
  }

  .js_about-img-wrap .shape_one {
    left: 0;
  }

  /* @keyframes MOVE-BG {
    0% {
      background-color: red;
      left: 0px;
    }
    25% {
      left: 46px;
      right: 0px;
    }
    50% {
      left: 46px;
      right: 0px;
    }
    75% {
      left: 0px;
      right: 0px;
    }
    100% {
      background-color: red;
      right: 0px;
    }
  } */
  /* .aj_data_circle {
    top: 41%;
    left: 23%;
  } */

  .dec_cirlce3 {
    bottom: 2%;
    display: none;
  }

  .dec_cirlce2 {
    top: -4%;
  }

  .icon {
    display: block;
  }

  .icon img {
    /* left: 112px; */
    left: 0;
    right: 0;
    margin: auto;
  }

  .outer-card-2 {
    order: 1;
  }

  .outer-card-1 {
    order: 2;
  }

  .main-section-tablet {
    display: flex;
    flex-flow: column;
  }
  .conact-card-1 h2 {
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
  }
  .conact-card-1 h4 {
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
  }

  .conact-card-1 img {
    width: 40px;
  }

  .conact-card-2 h2 {
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
  }

  .conact-card-2 h4 {
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
  }

  .conact-card-2 img {
    width: 40px;
  }
  .conact-card-2 {
    padding: 3px;
  }

  .conact-card-3 h2 {
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
  }

  .conact-card-3 h4 {
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
  }

  .conact-card-3 img {
    width: 25px;
    margin: 0px 8px;

    /* padding-top: 20px; */
  }
  .conact-card-inner {
    display: flex;
    align-items: center;
  }
  .conact-card-3 {
    padding: 3px;
  }

  .ajivainfotechmail {
    height: 22px;
    display: flex;
    align-items: center;
  }
  .outer-card-2 h1 {
    font-size: 12px;
  }
  .js_contact_content h1 {
    font-size: 14px;
  }
  .js_contact_content h2 {
    font-size: 20px;
  }
}

/* @media (min-width: 320px) and (max-width: 359px) {
  .aj_ensure_customers_bg {
    height: 151vh;
    background-size: cover;
  }
  .icon img {
    left: 111px;
  }
}
@media (min-width: 360px) and (max-width: 374px) {
  .aj_ensure_customers_bg {
    height: 104vh;
    background-size: cover;
  }
  .icon img {
    left: 131px;
    top: 27px;
  }
}
@media (min-width: 375px) and (max-width: 389px) {
  .aj_ensure_customers_bg {
    height: 140vh;
    background-size: cover;
  }
  .icon img {
    left: 138px;
  }
}
@media (min-width: 390px) and (max-width: 392px) {
  .aj_ensure_customers_bg {
    height: 82vh;
    background-size: cover;
  }
  .icon img {
    left: 146px;
    top: 27px;
  }
}
@media (min-width: 393px) and (max-width: 411px) {
  .aj_ensure_customers_bg {
    height: 82vh;
    background-size: cover;
  }
  .icon img {
    left: 148px;
    top: 27px;
  }
}
@media (min-width: 412px) and (max-width: 413px) {
  .aj_ensure_customers_bg {
    height: 76vh;
    background-size: cover;
  }
  .icon img {
    left: 156px;
    top: 26px;
  }
}
@media (min-width: 414px) and (max-width: 424px) {
  .aj_ensure_customers_bg {
    height: 78vh;
    background-size: cover;
  }
  .icon img {
    left: 157px;
    top: 26px;
  }
}
@media (min-width: 425px) and (max-width: 540px) {
  .aj_ensure_customers_bg {
    height: 128vh;
    background-size: cover;
  }
  .icon img {
    left: 163px;
    top: 26px;
  }
} */

/********************************************************* START * *************************************************************/
.bang h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding-top: 40px;
  word-spacing: 5px;
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif;
}

.Api_int {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #2a2e38;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph {
  width: 60%;
}

.spn {
  color: #ed112b;
}

.p2 {
  text-align: center;
  margin-top: 20px;
  padding-left: 7 em;
}

.use {
  padding-left: 10em;
  margin: 36px;
}

.imgs {
  display: flex;
  text-align: justify;
}

.font {
  font-size: 30px;
  color: #ec0013;
  padding-left: 28px;
  text-align: center;
  margin-left: 23px;
}

.cop {
  /* border: 1px solid red; */
  width: 100%;
}
/* 
.cd {
  width: 100%;
} */

.column {
  float: right;

  padding: 15px;
}

.card {
  padding-left: 40px;
  /* width: 33%; */
  margin-top: 10px;
}

.frist {
  width: 100%;

  padding-left: 10em;
  display: flex;
}

.w {
  width: 28%;
  margin: 10px;
}

.w2 {
  width: 30%;
}

.shedow {
  box-shadow: #2046ff;
}

.Phases {
  font-size: 30px;
  align-items: center;
  text-align: center;
  font-weight: 700;
}

.Requirment {
  border: 1px solid;
  border-radius: 50%;
  height: 93px;
  text-align: center;
  padding-top: 25px;
  width: 62%;
}

.section-para-contant {
  align-items: center;
  text-align: center;
  font-style: inherit;
}

.section-para-contant p {
  text-align: center;
  font-style: inherit;
  font-weight: inherit;
}

.process {
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 0px 0px 12px 1px rgb(223, 223, 223);

  border-radius: 10px;
  font-style: inherit;
  font-weight: inherit;
}

.word {
  word-spacing: 1px;
  letter-spacing: 1px;
}

.REQ {
  word-spacing: 5px;
  letter-spacing: 1px;
}

.web {
  color: red;
  font-weight: bolder;
  font-size: 30px;
}

.FIND_OUT {
  color: red;
}

.click {
  border: 1px solid red;
  width: 150px;
  height: 35px;
  background: red;
  border-radius: 7px;
  font-size: smaller;
  color: white;
  font-family: inherit;
  font-weight: inherit;
}

.gettouchbtn {
  padding-left: 13px;
  padding-top: 20px;
  cursor: pointer;
}

.online {
  text-align: center;
  padding-top: 19px;
}

.a:hover {
  color: red;
}

.ajiv-section-contant {
  width: 100%;
  align-items: start;
  padding: 0px;
  margin: 20px auto;
  justify-content: center;
}

.iconf {
  width: 14%;
  text-align: -webkit-center;
  cursor: pointer;
}

.testing_s {
  padding-left: 15px;
  margin-left: 10px;
}

.criclePHP {
  border-radius: 50%;
  border: 1px solid;
  height: 90px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
}

.cricledesign {
  border: 1px solid;
  border-radius: 50%;
  height: 90px;
  text-align: center;
  /* padding-top: 22px; */
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cricle {
  border: 1px solid;
  border-radius: 50%;
  height: 90px;
  text-align: center;

  width: 90px;
  display: flex;
  /* margin: auto; */
  align-items: center;
  /* text-align: justify; */
  justify-content: center;
}

.cricle1 {
  border: 1px solid;
  border-radius: 50%;
  height: 77px;
  text-align: center;
  padding-top: 19px;
  width: 77px;
}

.cricle8 {
  border: 1px solid;
  border-radius: 50%;
  height: 90px;
  text-align: center;
  /* padding-top: 25px; */
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.get_tuch {
  border: 1px solid;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  background: red;
}

@media (max-width: 576px) and (min-width: 320px) {
  .bang h1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-top: 15px;
    word-spacing: 2px;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
  }
  .ecomerece_border {
    border-radius: 0px !important;
  }
  .crm_para {
    padding-left: 1em;
    padding-right: 1em;
    text-align: justify;
  }

  .hhh {
    padding: 10px 16px;
  }

  .md_sreen {
    padding: 10px 15px;
  }

  .pl-8 {
    padding: 0px;
  }

  .logo-image-set {
    width: 85px !important;
  }

  .online {
    text-align: justify;
    padding-top: 20px;
    margin: 0px;
    padding: 0px;
    margin-left: 20px;
  }

  .imgs {
    display: block;
    /* text-align: justify; */
    padding: 0px;
  }

  .frist {
    width: 100%;
    padding-left: 0em;
    display: block;
  }

  .card {
    padding-left: 20px;
    width: 100%;
    margin-top: 10px;
  }

  .w {
    width: 100%;
    margin: 10px;
  }

  .mx-10 {
    margin-left: 2.5rem;
    margin-right: 0px;
  }

  .mx-20 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .p-3 {
    padding-left: 20px;
  }

  .element {
    text-align: justify;
  }

  .line {
    font-size: smaller;
  }

  .pl-5 {
    padding-left: 0px;
  }

  .process h {
    font-size: smaller;
  }

  .read {
    padding: 50px;
    color: red;
  }

  .Phases {
    font-size: 22px;
    align-items: center;
    text-align: center;
    font-weight: 700;
  }
}

/* @media (min-width: 320px) and (max-width: 359px) {
 .display_setting{
  display: block;
 }



} */
/* ----------------------------------dealmegnat---------------------------------------------------//////// */
/********************************************************* prikshit */

/* @media (min-width: 450px) and (max-width: 640px) {
  .js_about-img-wrap .shape_one {
    position: absolute;
    left: 80px;
    bottom: -20px;
    z-index: 1;
    max-width: 70%;
  }

  .js_about_img {
    margin-left: 50px;
  }
} */

/* @media (min-width: 300px) and (max-width: 449px) {
  .js_about-img-wrap .shape_one {
    position: absolute;
    left: 30px;
    bottom: -20px;
    z-index: 1;
    max-width: 80%;
  }

  .js_about_img {
    margin-left: 50px;
  }
} */
.case_in {
  width: 400px;
}

.case_in img {
  width: 100%;
}

.case_in .icons_hhh {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: none;
}

.case_in:hover .icons_hhh {
  display: block;
}

.case_in .overlay {
  opacity: 0;
  background-color: rgba(0, 0, 0);
}

.case_in:hover .overlay {
  opacity: 0.4;
}

/********************************************************* prikshit */

/* ---------------criclr-------------------------------------------------------- */

.cricle_setting {
  margin-left: 15px;
}

@media (min-width: 375px) and (max-width: 425px) {
  .dret {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wp-matinance-service-covers {
    font-size: 18px;
  }
  .main-payment-freq {
    width: 100%;
  }
  .wp-matinance-service-frequency {
    font-size: 18px;
  }
  .plansdescriptions {
    width: 100%;
  }

  .Requirment {
    height: 88px;
    width: 100%;
    /* margin-left: 25px; */
  }
  .ecomerece_border {
    border-radius: 0px !important;
  }
}

/* ------------------------------- */

.cop {
  /* border: 1px solid red; */
  width: 100%;
  height: 250px;
}

.ecom {
  text-align: justify;
  padding: 4px 9px;
}

.column {
  text-align: center;
  font-weight: 700;
}

.Requirment {
  border: 1px solid;
  border-radius: 50%;
  height: 93px;
  text-align: center;
  padding-top: 25px;
  width: 62%;
}

.perfection {
  padding: 8px 10px;
  text-align: justify;
}

.testing_s {
  padding-left: 15px;
  margin-left: 10px;
}

.crm_para {
  padding-left: 1em;
  padding-right: 1em;
  text-align: justify;
}

.px-20 {
  padding: 0px;
}

.md_sreen {
  padding: 10px 15px;
}

.Requirment {
  height: 87px;

  width: 100%;
}

.gettouchbtn {
  padding-left: 17px;
  padding-top: 20px;
  cursor: pointer;
  margin-left: 4%;
}
.discovery {
  padding: 10px 10px;
}

.bang h1 {
  font-size: 13px;
  /* font-weight: 600; */
}

.d2 {
  padding-top: 15px;
  text-align: center;
  line-height: 2;
}

.cricleplaning {
  border: 1px solid;
  height: 90px;
  text-align: center;
  /* padding-top: 22px; */
  width: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.criclesdevp {
  border: 1px solid;
  border-radius: 50%;
  height: 90px;
  text-align: center;
  /* padding-top: 22px; */
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Requirment {
  border: 1px solid;
  border-radius: 50%;
  height: 86px;
  text-align: center;
  padding-top: 22px;
  width: 86%;
}

.crm_s {
  padding: 10px 20px;
  text-align: justify;
  margin-top: 20px;
  line-height: 2;
  width: 100%;
  margin-left: 0px;
  /* padding-left: 0px; */
}

.frist_pr {
  width: 95%;
  margin: auto;
}

.cricleMaintenance {
  border: 1px solid;
  border-radius: 50%;
  height: 90px;
  text-align: center;
  /* padding-top: 22px; */
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.store {
  width: 100%;
  padding-left: 0rem;

  text-align: center;
  padding-top: 19px;
  padding: 0px 10px;
}

.online_web {
  text-align: justify;
  padding-top: 19px;
  width: 95%;
  margin: auto;
}

@media (min-width: 1024px) {
  .navbaar-responcive {
    /* padding: 0px 10px; */
    margin: 0px 10px;
  }
  .navbaar-responcive img {
  }
  .Requirment {
    border: 1px solid;
    border-radius: 50%;
    height: 85px;
    text-align: center;
    padding-top: 25px;
    width: 67%;
  }

  .big_screen {
    padding: 1em 2em;
    text-align: justify;
  }

  .cricle_tast {
    padding-left: 0.5em;

    .cricleplaning {
      border: 1px solid;
      border-radius: 50%;

      height: 85px;
      text-align: center;
      padding-top: 19px;
      width: 70%;
    }
  }
}

/* ---------------criclr-------------------------------------------------------- */

.cricle_setting {
  margin-left: 15px;
}

@media (min-width: 370px) and (max-width: 425px) {
  .Requirment {
    height: 88px;
    width: 100%;
    /* margin-left: 25px; */
  }

  .ecom {
    text-align: justify;
    padding: 4px 20px;
  }
  .box-frist-121 {
    position: relative;
    top: -37px !important;
    rotate: 43deg !important;
    right: -27px !important;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .clicrl_design {
    display: block;
    margin: auto;
  }
  .sectio-positon-change {
    display: flex;
    flex-direction: column-reverse;
  }
  .box-frist-121 {
    top: -7px !important;
    rotate: 36deg !important;
    right: -10px !important;
  }
  .web {
    font-size: 20px;
  }
  .our-purpose {
    padding: 20px;
  }
  .modal-details {
    width: 100% !important;
    margin: 0px;
    top: 50px;
  }
  .modal-details i {
    position: relative;
    top: -38px;
    right: -30px;
    background: #fff;
    border-radius: 50%;
  }

  .iconf {
    width: 81%;
    margin: auto;
    text-align: -webkit-center;
  }

  .process {
    margin: 5px;
  }

  .shopping {
    text-align: justify;
    margin-top: 20px;

    /* width: 100%; */
    /* margin-left: 0px; */
    padding: 0px 20px;
  }

  .drive {
    padding: 10px;
  }

  .ecom {
    text-align: justify;
    padding: 4px 20px;
  }

  .section-para-contant {
    padding: 0px 20px;
    text-align: justify;
  }

  .ajiv-section-contant {
    width: 100%;
    align-items: start;
    padding: 0px;
    margin: 20px auto;
    display: block;
  }

  .hhh {
    padding: 10px 16px;
  }

  .graph {
    width: auto;
  }

  .Requirment {
    height: 87px;

    width: 100%;
  }

  .gettouchbtn {
    padding-left: 20px;
    padding-top: 20px;
    cursor: pointer;
  }

  .discovery {
    padding: 10px 10px;
  }

  .dret {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bang h1 {
    font-size: 13px;
    /* font-weight: 600; */
    /* text-align: center; */
    /* padding-top: 15px; */
    /* word-spacing: 2px; */
    /* letter-spacing: 1px; */
    font-family: "Poppins", sans-serif;
  }

  .mobile {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #2a2e38;
    padding-top: 20px;
  }

  .goals_place {
    width: 95%;
    margin: 11px 10px 10px 10px;
  }

  .imgs {
    /* display: block; */
    /* text-align: justify; */
    padding: 5px 5px;
  }

  .online {
    text-align: justify;
    /* padding-top: 20px; */
    margin: 0px;
    padding: 4px 9px;
    /* margin-left: 20px; */
    margin-bottom: 10px;
  }

  .Our-Services-Graphic-Design h1 {
    display: flex;
    /* width: 265px !important; */
    margin: auto;
    justify-content: center;
    border-bottom: 2px solid #333333;
    font-size: 17px;
  }

  .d2 {
    width: 81%;
    margin: auto;
    text-align: -webkit-center;
  }
  .Platform_Integration {
    margin: auto;
    /* padding: 30px; */
    padding: 0px !important;
  }
  .Platform_Integration_outer {
    padding: 10px 20px !important;
    border-radius: 5px;
    gap: 5px;
  }
  .Allrightsreserved {
    margin-top: 13px;
  }
  .Allrightsreserved-inner {
    margin-left: 10px;
  }
  .sectio-positon-change ul {
    row-gap: 8px;
  }
  .footer_logo_211 img {
    width: 50%;
  }
  .footer_logo_211 {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    margin-top: 10px;
  }
  .Integration-Needs h1 {
    width: auto !important;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .unique {
    padding-left: 1em;
    padding-right: 1em;
  }
  .sectio-positon-change {
    display: flex;
    flex-direction: column-reverse;
  }
  .Requirment {
    border: 1px solid;
    border-radius: 50%;
    height: 86px;
    text-align: center;
    padding-top: 22px;
    width: 86%;
  }
  .main-section-tablet {
    display: flex;
  }
  .modal-details {
    width: 715px;
  }
  .coding_standards-outer {
    width: 85%;
  }
  .cricleMaintenance {
    border: 1px solid;
    border-radius: 50%;
    height: 90px;
    text-align: center;
    /* padding-top: 19px; */
    width: 90%;
  }

  .ecom {
    text-align: justify;
    padding: 4px 20px;
  }

  .graph {
    width: 95%;
  }

  .Research {
    padding: 10px 16px;
  }

  .drive {
    padding: 20px;
  }

  .cricle {
    border: 1px solid;
    border-radius: 50%;
    height: 88px;
    text-align: center;
    /* padding-top: 19px; */
    width: 88px;
  }

  .criclePHP {
    border-radius: 50%;
    border: 1px solid;
    height: 90px;
    text-align: center;
    /* padding-top: 19px; */
    width: 90px;
  }

  .criclesdevp {
    border: 1px solid;
    border-radius: 50%;
    height: 90px;
    text-align: center;
    /* padding-top: 19px; */
    width: 90%;
  }

  .cricle8 {
    border: 1px solid;
    border-radius: 50%;
    height: 92px;
    text-align: center;
    /* padding-top: 25px; */
    width: 90%;
  }

  .cricledesign {
    border: 1px solid;
    border-radius: 50%;
    height: 90px;
    text-align: center;
    /* padding-top: 22px; */
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cricleplaning {
    border: 1px solid;
    height: 90px;
    text-align: center;
    /* padding-top: 19px; */
    width: 90%;
    border-radius: 50%;
  }

  .crm_s {
    padding: 10px 20px;
    text-align: justify;
    margin-top: 20px;
    line-height: 2;
    width: 100%;
    margin-left: 0px;
    /* padding-left: 0px; */
  }

  .frist_pr {
    width: 95%;
    margin: auto;
  }

  .store {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .company {
    text-align: justify;
    margin-top: 20px;
    line-height: 2;
    width: 100%;
    margin-left: 0px;
    /* padding-left: 0px; */
    padding: 10px 20px;
  }

  .succed {
    width: 100%;
    padding-left: 0px;
    line-height: 2;
    padding: 10px 20px;
  }

  .Research {
    width: 95%;
    margin: auto;
    line-height: 2;
  }

  .imgs {
    display: block;
    text-align: justify;
  }

  .frist {
    width: 100%;
    padding-left: 0em;
    display: flex;
  }

  .Phases {
    font-size: 25px;
    align-items: center;
    text-align: center;
    font-weight: 700;
  }

  .geath {
    padding: 0px 0px;
  }

  .process {
    border: 1px solid rgb(226, 226, 226);
    box-shadow: 0px 0px 12px 1px rgb(223, 223, 223);
    padding: 0px;
    border-radius: 10px;
    font-style: inherit;
    font-weight: inherit;
  }

  .goal {
    text-align: justify;
    margin-top: 20px;
    line-height: 2;
    /* width: 100%; */
    /* margin-left: 0px; */
    padding: 0px 20px;
  }
  .criclePHP,
  .cricle {
  }
  .ajiv-section-contant {
    width: 100%;
    /* height: 130px; */
    align-items: start;
    padding: 0px;
    margin: 20px 0px;
    display: flex;
  }

  .element {
    text-align: justify;
  }

  .d2 {
    padding-top: 15px;
    text-align: center;
    line-height: 1;
  }

  .online {
    text-align: center;
    padding-top: 19px;
    padding: 0px 10px;
  }

  .online_web {
    text-align: justify;
    padding-top: 19px;
    width: 95%;
    margin: auto;
  }
  .card4 {
    padding: 10px !important;
  }
  .our_services {
    width: 95% !important;
    margin: auto !important;
    margin-bottom: 15px !important;
  }
}

@media (min-width: 1024px) {
  .git {
    width: 75%;
    padding: 10px 0px 1px 32px;
  }
  .company-details h1 {
    font-size: 18px !important;
    font-weight: 600;
  }
  .Ecommerce-bg-image {
    width: 90% !important;
  }

  .building-style p {
    font-size: 18px !important;
    line-height: 25px !important;
    margin-top: 10px !important;
  }
  .building-style {
    width: 60% !important;
  }
  .building-form-style {
    width: 40% !important;
  }
  .store {
    width: 100%;
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
    margin: auto;
  }

  .graph {
    width: 70%;
    padding: 10px 10px;
  }

  .Requirment {
    border: 1px solid;
    border-radius: 50%;
    height: 85px;
    text-align: center;
    padding-top: 25px;
    width: 62%;
  }

  .big_screen {
    padding: 1em 2em;
    text-align: justify;
  }

  .cricle_tast {
    padding-left: 0.5em;
  }

  .geath {
    padding-left: 0px;
    padding-right: 0px;
  }

  .goal {
    line-height: 2;
    width: 100%;
    /* margin-left: 0px; */
    /* padding-left: 0px; */
    /* margin: auto; */
    text-align: justify;
  }

  .frist {
    width: 100%;
    /* padding-left: 10em; */
    display: flex;
    padding-left: 30px;
  }
}

@media (min-width: 1440px) {
  .frist {
    width: 100%;
    padding-left: 0em;
    display: flex;
  }

  .need {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.absolute.bg-\[\#ff0000\].top-0.right-0.p-6.rounded-l {
  border-radius: 0px 0px 0px 120px;
}

.userarrowline {
  width: 1px;
  background-color: #8f8f8f;
  margin: auto;
  border: none;

  height: 70px;
}

.userarrowline_2 {
  width: 1px;
  background-color: #8f8f8f;
  margin: auto;

  border: none;

  height: 70px;
}

.shadow_box {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgb(0 0 0 / 56%) 0px 1px 4px 1px;
}

.show_section {
  display: none;
}

/* @media (min-width:320px) {
  .responcive {
    left: 50px;
  }
}

@media (min-width: 425px) {
  .responcive {
    left: 110px;
  }
} */
.font_setting {
  font-family: "Open Sans", sans-serif;
}

.logo_term_conditions {
  height: 50px;
  width: 120px;
}

.committed_to_ensuring {
  border: 1px solid #fff;

  padding: 10px;
  box-shadow: 0px 0px 10px 11px #ede2e2;
}

@media (min-width: 600px) and (max-width: 1000px) {
  .high {
    display: none;
  }
  .box-frist-121 {
    top: -3px !important;
    rotate: 45deg !important;
    right: -13px !important;
  }
  .our-purpose {
    padding: 20px;
  }
  .tablet_responcive {
    display: none;
  }
  .icon img {
    position: absolute;
    bottom: 36px;
    left: 0px;
    margin: auto;
    right: 0px;
  }
}
@media (min-width: 300px) and (max-width: 1000px) {
  .high {
    display: none;
  }
  .cop {
    height: auto !important;
  }
  .gettouchbtn {
    align-items: end;
    text-align: end;
  }
}
@media (min-width: 1000px) and (max-width: 3000px) {
  .card_1441 {
    display: none;
  }
  .blog_img_big {
    width: 100%;
  }
}
@media (min-width: 5000px) and (max-width: 6000px) {
  .card_1441 {
    display: none;
  }
  .blog_img_big {
    width: 100%;
  }
}
@media (min-width: 4000px) and (max-width: 5000px) {
  .card_1441 {
    display: none;
  }
  .blog_img_big {
    width: 100%;
  }
}
@media (min-width: 3000px) and (max-width: 4000px) {
  .card_1441 {
    display: none;
  }
  .blog_img_big {
    width: 100%;
  }
}

input:focus-visible {
  outline: 1px solid #978487;
  border-radius: 3px;
}

textarea#ajiva_tech:focus-visible {
  border: 1px solid gray;
}
textarea#ajiva_tech {
  border: 2px solid gray;
  width: 95%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}
.submit_Comments {
  border: 1px solid;
  border-radius: 10px;
  padding: 8px 30px;
  background-color: #2046ff;
  color: #fff;
}
textarea:focus-visible {
  outline: 1px solid;
  border-radius: 3px;
}

.objectives {
  height: 100px;
  width: 100px;
  margin: auto;
  border: 2px solid #d9e7f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #f0f7ff;
}

.functionality h1 {
  font-weight: 550;
}
.Platform_Integration p {
  font-weight: 300;
  text-align: justify;
  font-size: 14px;
}
.Platform_Integration h1 {
  font-weight: 550;
}

.Platform_Integration {
  margin: auto;
  padding: 30px;
}

.web_des_ser_offer li:before {
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: 3px;
  background-image: url(../src/aservecse_img/icon_ul.png);
  width: 16px;
  height: 16px;
  background-size: contain;
}
.web_des_ser_offer li {
  position: relative;
  padding-left: 25px;
  font-weight: 400;
  margin-bottom: 5px;
}
.web_des_ser_offer {
  padding: 15px 0px;
  color: #333333;
}
.Rapid_effective h1 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}
.Rapid_effective p {
  font-size: 14px;

  text-align: center;
}
.Rapid_effective {
  width: 80%;
  margin: auto;
  padding-top: 30px;
}

.Platform_Integration_outer {
  padding: 10px;
  border-radius: 5px;
  gap: 5px;
}
.ecomerece_border {
  border-radius: 60px 0px;
  display: flex;
  justify-content: center;
  box-shadow: -1px 0px 10px lightgray;
}
.ecomerece_border img {
  box-shadow: 0px 0px 0px 0px lightgray;
  height: 315px;
  width: 100%;
}
.ecomerece_border_frist {
  box-shadow: -1px 0px 10px lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px;
}

/* .ecomerece_bordeer_frist_main{
background-image: linear-gradient(to right, rgb(247, 235, 235) , rgb(218, 223, 220) ,rgb(255, 255, 255),rgba(223, 223, 241, 0.493));
} */
/* .ecomerece_bordeer_frist_main{
  background-image: linear-gradient(to right, rgb(185, 126, 126));
  } */

/* ======================ecomerce animation====================== */
.reveal {
  position: relative;
  /* opacity: 0; */
}

.reveal.active {
  opacity: 1;
}

.active.ecomerce-bottom {
  animation: ecomerce-bottom 3s ease-in;
}

.active.ecomerce-left {
  animation: ecomerce-left 3s ease-in;
}

.active.ecomerce-right {
  animation: ecomerce-right 3s ease-in;
}

@keyframes ecomerce-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes ecomerce-left {
  0% {
    transform: translateX(-400px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes ecomerce-right {
  0% {
    transform: translateX(400px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.bubble-wrap {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.bubbles-bg {
  position: relative;
  height: 100%;
  display: flex;
  gap: 22px;
  justify-content: center;
  width: 100%;
}
.bubbles-bg span {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: animate 5s linear infinite;
  animation-duration: calc(150s / var(--i));
}
@keyframes animate {
  0% {
    transform: translateY(350px) scale(0);
    background-color: #df6208;
    box-shadow:
      0px 0px 4px 4px #e67d31,
      0 0 30px #e67d31,
      0 0 70px #e67d31;
  }
  50% {
    background: #d3df08;
    box-shadow:
      0px 0px 4px 4px #e4ec53,
      0 0 30px #e4ec53,
      0 0 70px #e4ec53;
  }
  70% {
    background: #df0808;
    box-shadow:
      0px 0px 4px 4px #ec4242,
      0 0 30px #ec4242,
      0 0 70px #ec4242;
  }
  100% {
    transform: translateY(-10px) scale(1);
    background-color: #df6208;
    box-shadow:
      0px 0px 4px 4px #e67d31,
      0 0 30px #e67d31,
      0 0 70px #e67d31;
  }
}

/* ================================================================= */
.close-remove-icon {
  font-size: 50px;
}
/* .close-remove-icon-outer{
  position: absolute;
} */
.success-animation {
  position: relative;
  background: #fff;
  top: -55px;
  width: 70px;
  height: 70px;
  left: 0px;
  right: 0px;
  margin: auto;
  border-radius: 50%;
}

.checkmark {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: block;
  strokewidth: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  margin: auto;
  top: 7px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  strokewidth: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
.close-icon-cercle {
  border: 2px solid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.close-icon-outer {
  right: -25px;
  top: -30px;
  background: #fff;
  border-radius: 50%;
}
.main-section {
  height: 240px;
  width: 500px;
}
.submiting-text-animation {
  text-align: center;
  /* position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); */
  width: 100%;
}

.submiting-text-animation span {
  text-transform: uppercase;
  display: block;
}

.text1 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  background-color: #fff;
  position: relative;
  animation-name: text;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  height: 35px;
}

.text2 {
  font-size: 20px;
  font-weight: 600;
  color: red;
}

@keyframes text {
  0% {
    color: black;
    margin-bottom: -40px;
  }

  30% {
    letter-spacing: 10px;
    margin-bottom: -40px;
  }

  85% {
    letter-spacing: 5px;
    margin-bottom: -40px;
  }

  100% {
    margin-bottom: 5px;
  }
}
.sitemap-generator {
  width: 80%;
  margin: auto;
  padding: 20px;
  border: 2px solid gray;
  border-radius: 5px;
}

.logo-image-set {
  width: 10rem;
}
.logo-image-set img {
  width: 100%;
}

.Our_Services {
  font-size: 25px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.Our_Services h1 {
  display: flex;
  /* width: 202px; */
  margin: auto;
  justify-content: center;
  border-bottom: 2px solid #333333;
}

.Our-Services-Graphic-Design h1 {
  display: flex;
  /* width: 475px; */
  margin: auto;
  justify-content: center;
  border-bottom: 2px solid #333333;
}
.Our-Services-Graphic-Design {
  display: flex;
  font-size: 25px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Integration-Needs {
  font-size: 25px;
  font-weight: 700;
  display: flex;
  margin: auto;
  text-align: center;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Integration-Needs h1 {
  display: flex;
  /* width: 610px; */
  margin: auto;
  justify-content: center;
  border-bottom: 2px solid #333333;
}
.iconf :hover {
  color: red;
}
.iconf:hover span {
  color: red;
}

.iconf:visited {
  color: red;
}

.iconf.active {
  color: red; /* Change to the desired style for active state */
}
.iconf.active span {
  color: red;
  /* Change to the desired style for active state */
}
.active .cricle {
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
  height: 100px;
  width: 100px;
}
.our-purpose {
  border: 3px solid red;
}

.box-frist-121 {
  height: 25px;
  width: 46px;
  border-bottom: 3px solid red;
  float: inline-end;
  position: relative;
  top: 21px;
  rotate: 44deg;
  right: -13px;
  background: #fff;
}
.card_into_linespace a {
  text-decoration: underline;
  color: #165dd7;
}
.card_into_linespace {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.card_blog {
  background-color: #f4f5f7;
  transition: all 0.4s ease;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
}
.blo {
  width: 100% !important;
  padding: 15px;
}

.home-spacing .mainSpace {
  min-height: auto !important;
}

.blo img {
  width: 100% !important;
  border: 1px solid #afadad;
  padding: 5px;
  cursor: pointer;
}

.blo img:hover {
  box-shadow: 0 0 6px 1px rgba(33, 153, 232, 0.5);
}

.blo_content {
  padding: 30px 10px 0 10px;
}

.blo_content span {
  font-size: 16px;
  font-weight: 400;
}

.blo h1 {
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 23px;
  color: #646464;
  margin-bottom: 0.5em;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.box-seciond-121 {
  height: 65px;
  width: 50px;
  border-top: 3px solid red;
  position: relative;
  top: -29px;
  rotate: 44deg;
  right: 27px;
  background: #fff;
}
/* ==================new header css============================= */
.cp-header {
  background: #fff;
  box-shadow: 1px 2px 15px 0 hsla(0, 0%, 66%, 0.28);
  -webkit-box-shadow: 1px 2px 15px 0 hsla(0, 0%, 66%, 0.28);
  -moz-box-shadow: 1px 2px 15px 0 hsla(0, 0%, 66%, 0.28);
  left: 0;
  padding: 0.8rem 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.6s;
  z-index: 1001;
}
/* =============================new navbaar togle=========================== */

@keyframes slideLeftToRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@media (min-width: 300px) and (max-width: 1000px) {
  .nav_v.slide-in-left {
    animation: slideLeftToRight 0.5s ease-in-out;
  }
  .nav_v.slide-in-right {
    animation: slideRightToLeft 0.5s ease-in-out;
  }

  .API_Integration_includin li {
    column-gap: 10px;
  }

  .coding_standards {
    display: flex;
    justify-content: center;
    margin: 0px 0px;
  }
  .Coding_Validation {
    width: 100% !important;
  }
  .Integration_including li {
    column-gap: 10px;
  }
}

/* =============================new navbaar togle end=========================== */
.designsolutions {
}
.API_Integration_includin li {
  column-gap: 10px;
}
.enhanceflexibility button {
  border: 1px solid;
  padding: 0px 10px;
  border-radius: 10px;
}
.Integration_including li {
  column-gap: 10px;
}
.button-card {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  background: #ffffff;
  border: 2px solid #d7d6d6;
  color: #444444;
  border-radius: 10px;
  width: 20rem;
}
.button-card-outer-section {
  column-gap: 15px;
  padding: 2rem;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  margin: 2rem 0rem;
  width: 56%;
  margin: auto;
}
.TOOLS {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #343434;
}
.button-card-image {
  border-right: 2px solid #cecdcd;
  text-align: center;
  margin: auto;
  align-items: baseline;
  justify-content: center;
  display: flex;
}
.seciond-row-section {
  margin-top: 25px;
}
.button-card:hover {
  border: 1px solid #000;
  border-radius: 10px 10px 0px 0px;
}
.button-card.active {
  border: 1px solid #000;
  border-radius: 10px 10px 0px 0px;
}
.Coding_Validation {
  width: 58%;
  margin: auto;
}

.first-of-type {
  display: flex;
  background-size: 100%;
  background-repeat: no-repeat;
  /* background-image: url(./img/banner-website--2.jpg); */
}
.slider_three {
  /* background-image: url(/static/media/banner-website--5.b666099b.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  padding-top: 50px;
  background-size: cover;
  margin-top: 100px;
}
.with_pra_one {
  font-weight: 100;
  font-size: 30px;
  color: #807d7d;
  font-family: "Inter", sans-serif;
}
.typewriter_frist {
  padding-left: 60px;
  width: 21.5%;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid #212121;
  animation:
    cursor 1s step-start infinite,
    text 5s steps(18) alternate infinite;
}
.slider_one {
  /* background-image: url(/static/media/banner-website--2.1f5d04b6.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  padding-top: 50px;
  background-size: cover;
  margin-top: 100px;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}
/* ============================================================================= */
/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600);



#slider {
  position: relative;
  overflow: hidden;
  margin: 20px auto 0 auto;
  border-radius: 4px;
}

#slider ul {
  position: relative;
  margin: 0;
  padding: 0;
  height: 300px;
  list-style: none;
  display: flex;
  transition: transform 0.2s ease;
}

#slider ul li {
  position: relative;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 500px;
  height: 300px;
  background: #ccc;
  text-align: center;
  line-height: 300px;
}

a.control_prev, a.control_next {
  position: absolute;
  top: 40%;
  z-index: 999;
  display: block;
  padding: 4% 3%;
  width: auto;
  height: auto;
  background: #2a2a2a;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  cursor: pointer;
}

a.control_prev:hover, a.control_next:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
}

a.control_prev {
  border-radius: 0 2px 2px 0;
}

a.control_next {
  right: 0;
  border-radius: 2px 0 0 2px;
}

.slider_option {
  position: relative;
  margin: 10px auto;
  width: 160px;
  font-size: 18px;
} */
/* .hover-bacground-property {
  align-items: end;
  display: flex;
  height: 100px;
  justify-content: end;
} */
/* .hover-bacground-property :hover {
  border: 1px solid red;
  background-color: #ff6800;
  border-radius: 33px;
  height: 115px;
  width: 120px;
  position: relative;
  left: 20px;
  top: 20px;
  border-radius: 50px 0px 0px 0px;
} */
.icone-background {
  height: 40px;
  width: 40px;
  display: flex;
  margin: auto;
  background: #f8f8f8;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* .inner-section-hover :hover {
  background: red;
  width: 100px;
  height: 100px;
} */

.card4121 {
  display: block;
  top: 0px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 18px 20px;
  text-decoration: none;
  transition: all 0.5s;
  overflow: hidden;
  border: 1px solid #cccccc;
  border-right-color: red;
  border-right-width: 2px;
}
/* ===============================card hover=================================== */
.card_hover {
  position: relative;
}

.btn-hover-background {
  padding: 0px 0px;
  position: relative;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  font-family: monospace;
  border-radius: 100% 70% 0% 70% !important;
  height: 130px;
  width: 130px;
  left: 45px;
  top: -20px;
  overflow: hidden;
  letter-spacing: -1px;
  background-color: #f8f8f8;
  transition:
    transform 0.5s ease,
    background-color 0.5s ease;

  [class^="btn__text"] {
    font-size: 24px;
  }

  .btn__text-static {
    z-index: 1111;
    position: relative !important;
    display: flex;

    justify-content: center;
  }

  .btn__text-dynamic,
  .btn__text-dynamic-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: all ease 0.5s;
    overflow: hidden;
    border-radius: 50%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top left, #ec0013, #343434);
    z-index: 1;
    transition: all ease 0.5s;
    transform: translateX(100%) translateY(100%);
  }
}

.card_hover:hover .btn-hover-background {
  -ms-zoom-animation: 5px;
}

.card_hover:hover .btn-hover-background::before {
  transform: translateX(0) translateY(0);
}

.card_hover .btn__text-dynamic-inner img {
  display: block;
}

.card_hover:hover .btn__text-dynamic-inner img#image-2 {
  display: none;
}

.card_hover:hover .btn__text-static img#image-1 {
  display: block;
}

.card_hover .btn__text-static img#image-1 {
  display: none;
}

.hover-bacground-property {
  height: 90px;
}

.grid-section {
  row-gap: 25px;
  column-gap: 25px;
}

.button-card-image img {
  height: auto;
  width: auto;
}

.grid-section {
  row-gap: 25px;
  column-gap: 25px;
}

.button-card-image img {
  height: auto;
  width: auto;
}

/* ======================================================================== */
@media (min-width: 1000px) and (max-width: 1200px) {
  .coding_standards-outer {
    width: 95% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .coding_standards-outer {
    width: 70% !important;
  }
}
.card4:hover {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -6px;
}

.card_hover {
  background-image: url("../src/Newicon_img/done/cardbackgroundimage.png");
}
.card4 {
  background-image: url("../src/Newicon_img/done/cardbackgroundimage.png");
  background-size: cover;
}

.card4:hover .icone-background {
  background: linear-gradient(to top left, #ec0014da, #1a1919);
}
.card4:hover .icone-background i {
  color: #fff;
  font-size: 20px;
}

.card_hover:hover .btn__text-dynamic {
  display: none;
}
.card_hover {
  overflow: hidden; /* Ensures the zoom effect doesn't overflow the card */
  transition: transform 0.2s ease-in-out; /* Smooth transition for the zoom effect */
}

.card_hover:hover {
  transform: scale(1.08); /* Zoom in by 5% */
}

/* ---------------------slider------------------------- */
#unique-slider {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.unique-slider-container {
  position: relative;
}

.unique-slider-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unique-slider-image {
  width: 100%;
  height: auto;
}

.unique-slider-left-arrow,
.unique-slider-right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.unique-slider-left-arrow {
  left: 10px;
}

.unique-slider-right-arrow {
  right: 10px;
}

.unique-slider-dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 15px;
}

.unique-slider-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.unique-slider-dot.active {
  background-color: white;
}

.Ensure-Customers {
  font-size: 13px;
  text-align: justify;
  line-height: 1.5;
  word-spacing: 1px;
  font-weight: 500;
}
.Testimonials-main .Testimonials {
  font-size: 13px;
  font-weight: 500;
}
.Ecommerce-bg-image {
  background-image: url("./img/banner/Ecommerce.png");
  padding: 25px 0px;
  /* height: 550px; */
}

.Consultation-style {
  background: #ee1021;
  /* color: #fff; */
  border-radius: 10px;
  padding: 30px;
}

.building-style h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
}
.building-style h2 {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
}
.building-style p {
  font-size: 22px;
  font-weight: 400;
  line-height: 48px;
  color: #616161;
}
.building-style {
  width: 70%;
}
.building-form-style {
  width: 30%;
}
.Consultation-style h1 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.Consultation-style h2 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}
.Ecommerce-form-style input {
  border-radius: 5px;
}
.border-style {
  border-radius: 5px;
}
.Ecommerce-form-style textarea {
  border-radius: 5px;
}
.hire-developer button {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 6px 0px 0px #00000040;
  font-size: 16px;
  color: #2f2f2f;
  font-weight: 500;
}

.company-details h1 {
  font-size: 25px;
  font-weight: 600;
}

.commerce-website h2 {
  font-size: 25px;
  color: #343434;
}
.commerce-website p {
  font-size: 16px;
  font-weight: 500;
  color: #595959;
}
.commerce-website ul li {
  font-size: 16px;
  font-weight: 500;
  color: #595959;
}
.ecommerce-heading h2 {
  font-size: 40px;
  font-weight: 800;
  color: #343434;
}
.ecommerce-heading h1 {
  font-size: 30px;
  font-weight: 500;
  color: #515151;
}

.details-card .explore {
  letter-spacing: 1px;
  background: none;
  color: white;
  position: relative;
  outline: none;
  border: none;
  height: 50px;
  width: 190px;
  font-size: 14px;
  z-index: 2;
  border-radius: 5px;
  transition: 0.01s 0.23s ease-out all;
  overflow: hidden;
  background: linear-gradient(to right, #3e3838 10%, #ff0000 100%) !important;
}
.details-card .explore::before {
  content: "Consult Now";
  position: absolute;
  left: 0;
  top: 12px;
  height: 100%;
  bottom: 0px;
  color: #fff;
  width: 100%;
  z-index: -1;
  transition: 0.3s ease-in all;
  font-weight: 700;
  font-size: 16px;
}
.details-card .explore::after {
  content: "";
  position: absolute;
  left: -5%;
  top: 5%;
  height: 90%;
  width: 5%;
  z-index: -1;
  transition: 0.4s 0.02s ease-in all;
}
.details-card .explore:hover {
  cursor: pointer;
  color: transparent;
  font-weight: 600;
  background: linear-gradient(to right, #ff0000 10%, #3e3838 100%) !important;
}
.details-card .explore:hover::before {
  left: 100%;
  /* width: 25%; */
}
.details-card .explore:hover::after {
  left: 100%;
  width: 70%;
}
/* Icon Right Styling */
.icon-right {
  position: absolute;
  top: 0;
  right: 0;
}
.icon-right::after {
  font-family: "FontAwesome";
  content: "\2192";
  font-size: 24px;
  display: inline-block;
  position: relative;
  top: 26px;
  transform: translate3D(0, -50%, 0);
}
.icon-right.after::after {
  left: -250px;
  color: black;
  transition:
    0.15s 0.25s ease left,
    0.5s 0.05s ease color;
}
.icon-right.after::before {
  /* content: "Consult Now"; */
  position: absolute;
  left: -230px;
  top: 14px;
  opacity: 0;
  transition: 0.2s ease-in all;
}
.details-card .explore:hover .icon-right.after::after {
  left: -80px;
  color: white;
  transition: 0.2s 0.2s ease all;
}
.details-card .explore:hover .icon-right.after::before {
  left: -104px;
  top: 14px;
  opacity: 0.2;
  color: white;
}
.Start_Getting_Found {
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.whytochoose h1 {
  font-size: 40px;
  /* text-align: end; */
  font-weight: 700;
}
.whytochoose ul li {
  font-size: 18px;
  /* text-align: end; */
  font-weight: 500;
  margin-top: 10px;
}
.whytochoosecard:hover .whytochoosecardshow {
  display: block;
}

@media (min-width: 1534px) and (max-width: 1650px) {
  .header-section .navbaar-responcive {
    padding: 0px 50px;
  }
  .footer-section {
    padding: 0px 50px;
  }
  .Ecommerce-bg-image {
    padding: 0px 0px;
  }
  .responcivemedia {
    padding: 40px 60px !important;
  }
}

.Integrationservices h2 {
  font-size: 35px;
  font-weight: 800;
  color: #343434;
}

.benefits h1 {
  font-size: 18px;
  font-weight: 600;
  color: #343434;
}

.benefits p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #6a6a6a;
  padding: 10px 0px;
  padding-right: 60px;
}
.benefits-bottom {
  border-bottom: 1px dashed #999999;
}
.benefits {
  padding: 20px 0px;
}
.Integrationservices {
  gap: 30px;
}

.ajv-section-middle .ajv-section-middle-inner {
  display: flex;
  margin: auto;
  border-radius: 50%;
  padding: 30px;
  border: 2px dashed #999999;
}

@keyframes ping-slow {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes ping-slow-1 {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
@keyframes ping-slow-2 {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(-10px, 15px);
  }
  75% {
    transform: translate(-15px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.node-1 {
  top: 10%;
  left: 20%;
  animation: floating 3s infinite ease-in-out;
}

.node-2 {
  bottom: 15%;
  right: 10%;
  animation: floating 4s infinite ease-in-out reverse;
}

.node-3 {
  top: 30%;
  right: 25%;
  animation: floating 2.5s infinite ease-in-out;
}

.node-4 {
  bottom: 20%;
  left: 15%;
  animation: floating 3.5s infinite ease-in-out;
}

.animate-ping-slow {
  animation: ping-slow 3s infinite ease-out;
}

.animate-ping-slow-1 {
  animation: ping-slow 3s infinite ease-out;
}

.animate-ping-slow-2 {
  animation: ping-slow 3s infinite ease-out;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes arrow-flow {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-spin-slow {
  animation: spin-slow 6s linear infinite;
}

.animate-arrow {
  animation: arrow-flow 2s ease-in-out infinite;
}

.animate-arrow-reverse {
  animation: arrow-flow 2s ease-in-out infinite reverse;
}

.animate-float {
  animation: float 4s ease-in-out infinite;
}

.animate-float-delay {
  animation: float 4s ease-in-out infinite 2s;
}
.salesforce {
  display: flex;
  border: 1px dashed #999999;
  border-radius: 50%;
  padding: 20px;
  background-color: #ffffff;
}

.ajv-enterprise {
  border: 1px dashed #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  width: 80%;
  border-radius: 10px;
  display: flex;
  padding-left: 10%;
  background-color: #f8f8f8;
}

.ajv-automation {
  position: relative;
  left: 50px;
  display: flex;
  align-items: center;
}

.ajv-enterprise h1 {
  font-size: 18px;
  font-weight: 500;
}

.ajv-enterprise p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}
.Platformspecific-1 {
  left: -120px;
}
.Platformspecific-2 {
  left: -45px;
}
.Platformspecific-3 {
  left: -120px;
}
.ajv-Platformspecific {
  width: 90%;
}

/* .ajv-Platformspecific img {
  height: 490px;
} */
.ajv-API-Integration-heading {
  position: relative;
  top: 35px;
}
.ajv-API-Integration-card {
  display: inline-grid;
  gap: 60px;
  position: relative;
  top: 23px;
}

.choosing-section h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
.choosing-section p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6a6a6a;
  text-transform: capitalize;
}
.choosing-section .ajv-Platformspecific-inner-section {
  line-height: 48px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: translateY(-100%);
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Utility classes for animations */
.animate-fade-in {
  animation: fadeIn 3s ease-in-out;
}

.animate-slide-in {
  animation: slideInFromLeft 3s ease-in-out;
}

.animate-scale-up {
  animation: scaleUp 5s ease-in-out;
}

@keyframes fadeSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-image {
  animation: fadeSlideUp 4s ease-out forwards;
}
@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-heading {
  animation: slideFromLeft 3s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-content {
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1.2s ease-out forwards;
  animation-delay: 0.5s; /* Slight delay */
}
.form-content form {
  background: #ffffff21;
  padding: 20px;
  border-radius: 15px;
}

/* =============================ajv-maintenance-frustrated=================================================================== */
/* Apply Poppins font to everything inside .ajv-maintenance-page */
.ajv-maintenance-pages {
  font-family: "Poppins", serif;
}
.ajv-maintenance-page-inner {
  /* background-image: url("./img/wpmantinance/Groupbg.png"); */
  background-color: #000;
  position: relative;
  /* z-index: -11111111111; */
}

/* .ajv-maintenance-page .ajv-maintenance-page-inner {
  position: relative;
} */

.maintenance-left {
  font-size: 49px;
  font-weight: 600;
  color: #fff;
}

.ajv-maintenance-frustrated p {
  font-size: 20px;
  font-weight: 300;
  /* line-height: 35px; */
  color: #fff;
}
/* =======================================================start-1===================================================== */
.ajv-maintenance-frustrated button {
  background: linear-gradient(90deg, #ee1021 0%, #ec0f20 100%);
  border-top: 3px solid #af000d;
  /* box-shadow: 0px 1px 41px 0px #e40f1f; */
  padding: 15px 70px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  /* transition: all 0.3s ease; 
animation: pulse 1.5s ease-in-out infinite;  */
}

.Excellence-Experience {
  position: absolute;
  font-size: 15px;
  border: 2px solid #ee1021;
  border-radius: 57px;
  line-height: 20px;
  padding: 3px 10px;
  left: 55px;
  display: flex;
  top: -15px;
  background: #ee1021;
  text-shadow: 2px 2px #ff0000;
}
/* Add keyframe animation for a pulse effect */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Slightly enlarge the button */
  }
  100% {
    transform: scale(1);
  }
}

/* Optional: Add a focus animation for when the button is focused */
.ajv-maintenance-frustrated button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0px 4px 20px 0px rgba(228, 15, 31, 0.5); /* Glow effect on focus */
}
.page-template-page-tldr_preview-inner {
  position: relative;
  z-index: 111;
}
/* ===============================================================end-1 ====================================================== */

.membership-benefits-section .membership-benefits-heading h2 {
  font-size: 50px;
  font-weight: 800;
  color: #343434;
}
.membership-benefits-section .membership-benefits-heading p {
  font-size: 20px;
  font-weight: 300;
  line-height: 41px;
}
.image-membership-inner-section {
  position: relative;
  z-index: 999;
}
.animated_image_section {
  top: 30%;
  border: 1.8px dashed #6a6a6ad1;
  border-radius: 50%;
  background-color: #fbfbfb63;
  height: 350px;
  width: 350px;
  right: 0%;
  bottom: 0px;
  display: flex;
  padding: 40px;
  padding-top: 0px;
}

.animated_image_section img {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 25px;
  object-fit: contain;
}

.membership-image-mid {
  height: 170px;
  width: 135px;
  margin: auto;
  margin-left: 0%;
  margin-top: 24%;
  transform: rotate(290deg);
}
.membership-image-mid img {
  width: 100%;
  height: 100%;
}
.membership-benefits-section .membership-benefits-heading span {
  color: #ee1021;
  border: 1px solid #ee1021;
  border-radius: 40px 40px 40px 40px;
  padding: 2px 12px;
  margin: 0px 5px;
}
.performing-inner-section h1 {
  font-size: 50px;
  font-weight: 700;
  width: 80%;
  color: #343434;
}
.performing-section-details p {
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  color: #4e4e4e;
}
.performing-section-details button {
  background: linear-gradient(90deg, #ee1021 0%, #880913 100%);
  font-size: 20px;
  font-weight: 600;
  /* line-height: 30px; */
  color: #fff;
  padding: 14px 29px;
  border-radius: 10px;
}
.performing-inner-section span {
  color: #ee1021;
  border: 1px solid #ee1021;
  border-radius: 40px 40px 40px 0px;
  padding: 2px 12px;
}
.ajv-maintenance-frustrated ul {
  font-size: 18px;
  font-weight: 300;
  line-height: 45px;
}
.ajv-maintenance-frustrated ul li {
  /* margin-top: 15px; */
  color: #fff;
}
.maintenance-left span {
  color: #ee1021;
  border: 1px solid #ee1021;
  border-radius: 40px 40px 40px 0px;
  padding: 2px 12px;
}

.carve-bg {
  background: #333;
  position: relative;
}
.form-container h2 {
  font-size: 27px;
  font-weight: 600;
  /* line-height: 28px; */
  text-align: center;
  color: #fff;
}
.form-container form {
  width: 70%;
  margin: auto;
  margin-right: 20px;
}
.form-input-section {
  width: 100%;
  margin: 20px 0px;
}
.form-input-section input {
  width: 100%;
  height: 45px;
  border: 1px solid #9b9b9b;
  padding: 10px;
  border-radius: 8px;
  color: #000;
  /* display: table-footer-group; */
  font-size: 13px;
}
.form-input-section textarea {
  width: 100%;
  /* height: 45px; */
  border: 1px solid #9b9b9b;
  padding: 10px;
  border-radius: 8px;
  color: #000;
  /* display: table-footer-group; */
  font-size: 13px;
}

.form-content-btn .details-card .explore {
  width: 100%;
}

.form-content-btn .icon-right.after::after {
  left: -590px;
}

.form-content-btn .details-card .explore:hover .icon-right.after::after {
  left: -265px;
}
.form-content-btn .details-card .explore::before {
  content: "Submit";
}
.image-container {
  position: relative;
  display: flex;
}
.image-container-svg {
  position: absolute;
  top: 0px;
  right: 0px;
}
.performing-section-details {
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
}
.image-container-inner {
  height: 365px;
  position: absolute;
  right: 20px;
}
.image-container-inner img {
  object-fit: contain;
}
.page-template-page-tldr_preview {
  position: relative;
}

.What-You-Missing-heading {
  font-size: 50px;
  font-weight: 700;
  color: #343434;
  /* text-shadow: 1px 1px 1px 0px #34343440; */
}
.What-You-Missing-heading span {
  color: #ee1021;
  border-radius: 40px 40px 40px 0px;
  border: 1px solid #ee1021;
}
.What-You-Missing-section p {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin: auto;
  width: 100%;
}

.ajv-maintenance-pages .What-You-Missing-section button {
  border-radius: 44px;
  background: linear-gradient(90deg, #ee1021 0%, #880913 100%);
  font-size: 20px;
  padding: 15px 70px;
  box-shadow:
    0px 8px 9px rgba(0, 0, 0, 0.25),
    0px 7px 2px 0px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.12),
    0 12px 13px rgba(0, 0, 0, 0.17),
    0 -3px 5px rgba(0, 0, 0, 0.09);
  font-weight: 500;
  color: #fff;
}
.Behind-section-inner h2 {
  font-size: 50px;
  font-weight: 500;
  color: #343434;
}
.Behind-section-inner p {
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  color: #4e4e4e;
}
.behind-icon-section {
  height: 90px;
  width: 90px;
  display: flex;
  padding: 25px;
  /* border: 1px solid #ead5d7; */
  border-radius: 10px;
  border-left: 3px solid #0073aa;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.behind-icon-section svg {
  margin: auto;
}
.Behind-section-inner span {
  color: #ee1021;
  font-weight: 500;
  border-radius: 40px 40px 40px 0px;
  border: 1px solid #ee1021;
  background-color: #fff;
}
.Wordpress-line-1 {
  width: 100%;
  border-bottom: 2px dashed #6a6a6a;
  height: 2px;
}
.wordpress-line-2 {
  height: 60px;
  border-right: 2px dashed #6a6a6a;
  width: 2px;
  margin: auto;
}
.wordpress-line-3 {
  height: 70px;
  border-right: 2px dashed #6a6a6a;
  width: 2px;
  margin: auto;
}
.wordpress-line-4 {
  height: 270px;
  border-right: 2px dashed #6a6a6a;
  width: 2px;
  margin: auto;
}
.wordpress-line-5 {
  height: 70px;
  border-right: 2px dashed #6a6a6a;
  width: 2px;
  margin: auto;
}
.wordpress-processe-section {
  width: 65%;
  margin: auto;
}
.items-processe-section-1 {
  position: absolute;
  top: 35px;
  width: 15%;
}
.items-processe-section-5 {
  position: absolute;
  top: 35px;
  right: 0px;
  width: 15%;
}
.items-processe-section {
  width: 20%;
}

.items-processe-section-1 .outer-circle {
  background: #feb155;
  border: 3px solid #ffd8aa;
  padding: 20px;
  border-radius: 50%;
}
.items-processe-section-1 .inner-circle {
  background: linear-gradient(135.7deg, #fece94 19.07%, #feb155 84.16%);
  box-shadow: 3px 3px 0px 0px #00000040;
  padding: 35px;
  border-radius: 50%;
}

.items-processe-section-5 .outer-circle {
  background: #2f39bf;
  border: 3px solid #979cdf;
  padding: 20px;
  border-radius: 50%;
}
.items-processe-section-5 .inner-circle {
  background: linear-gradient(219.45deg, #979cdf 16.09%, #2f39bf 85.64%);
  box-shadow: 3px 3px 0px 0px #00000040;
  padding: 35px;
  border-radius: 50%;
}
.items-processe-section-2 .outer-circle {
  background: #b865d8;
  border: 3px solid #dbb1eb;
  padding: 20px;
  border-radius: 50%;
}
.items-processe-section-2 .inner-circle {
  background: linear-gradient(135.7deg, #d9aeeb 19.07%, #a057bd 84.16%);
  box-shadow: 3px 3px 0px 0px #00000040;
  padding: 35px;
  border-radius: 50%;
}
.items-processe-section-4 .outer-circle {
  background: #09ebaf;
  border: 3px solid #7df5d5;
  padding: 20px;
  border-radius: 50%;
}
.benefits-list-section li p {
  font-size: 20px;
  font-weight: 500;
  line-height: 41px;
  color: #343434;
}
.items-processe-section-4 .inner-circle {
  background: linear-gradient(219.45deg, #84f5d7 16.09%, #09ebaf 85.64%);
  box-shadow: 3px 3px 0px 0px #00000040;
  padding: 35px;
  border-radius: 50%;
}

.items-processe-section-3 .outer-circle {
  background: #fbd808;

  border: 3px solid #ffd8aa;
  padding: 20px;
  border-radius: 50%;
}
.items-processe-section-3 .inner-circle {
  background: linear-gradient(228.16deg, #fdeb83 15.14%, #fbd808 86.61%);
  box-shadow: 3px 3px 0px 0px #00000040;
  padding: 35px;
  border-radius: 50%;
}
.benefits-list-section .behind-icon-section {
  background: #ecf3ff;
  border-left: 3px solid #0073aa;
}
/* =================================babal animation===================================================== */

/* ==========================babal end =================================================================================== */

.hecked_section_background {
  background-image: url("./img/wpmantinance/hecked_section_img.gif");
  background-size: contain;
  background-repeat: repeat;
  border-radius: 20px 20px 20px 20px;
}
.hacked-inner-section .percentage {
  font-family: Akaya Kanadaka;
  font-size: 114px;
  font-weight: 400;
  line-height: 95px;
  color: #fff;
}
.Until-section .Until-icon-section {
  height: 90px;
  width: 90px;
  display: flex;
  border-top: 3px solid #fce100;
  border-radius: 10px;
  background: #ffffff;
}
.Until-section .Until-icon-section svg {
  margin: auto;
}

.hacked-inner-section .percentage-content {
  font-size: 34px;
  font-weight: 500;
  background-color: #ee1021;
  text-shadow:
    1px 1px 2px black,
    0 0 1em #e70f28,
    0 0 0.2em #e91021;
}
.hacked-inner-section h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 52px;
  color: #fff;
}
.hacked-inner-section h2 span {
  font-size: 57px;
  font-weight: 400;
  line-height: 52px;
  color: #fff;
}

.hacked-inner-section {
  width: 40% !important;
}
.percentage-content-details {
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
}

.Until-section .dont-wait-section h2 {
  font-size: 50px;
  font-weight: 800;
  color: #ee1021;
}
.Until-section .dont-wait-section h2 span {
  color: #ee1021;
  border-radius: 40px 40px 40px 40px;
  border: 1px solid #ee1021;
  background-color: #fff;
  padding: 0px 22px;
}
.Until-section .dont-wait-section p {
  font-size: 25px;
  font-weight: 600;
  /* line-height: 35px; */
  color: #242424;
}
.dont-wait-section button {
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  border-top: 3px solid #af000d;
  background: linear-gradient(90deg, #ee1021 0%, #880913 100%);
  border-radius: 50px;
  box-shadow: 0px 3px 4px 0px #00000040;
  text-transform: capitalize !important;
}
.dont-wait-section button:hover {
  box-shadow: 0px 1px 7px 0px #e40f1f;
}

.dont-wait-section {
  border: 2px dashed #ee1021;
  border-radius: 15px;
}
.dont-wait-section-background {
  background-color: #fce100;
  border-radius: 15px;
}
.your-wordpress-website-heading h2 {
  font-size: 50px;
  font-weight: 800;
  /* line-height: 72px; */
  margin: auto;
  color: #343434;
}

.your-wordpress-website-heading span {
  color: #ee1021;
  border-radius: 40px 40px 40px 40px;
  border: 1px solid #ee1021;
  font-weight: 700;
  padding: 2px 30px;
}
button.wordpress-btn {
  width: 100%;
  padding: 10px;
  background: #ee1021;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  text-shadow: 2px 2px #ff0000;
}
/* ================================================================================================================================================== */

.ajv-section-left .benefits-section-1 {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  padding: 20px;
  border-radius: 10px 10px 150px 10px;
}
.ajv-section-left .benefits-section-2 {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  padding: 20px;
  border-radius: 10px 150px 150px 10px;
}
.ajv-section-left .benefits-section-3 {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  padding: 20px;
  border-radius: 10px 150px 10px 10px;
}
.ajv-section-right .benefits-section-4 {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  padding: 20px;
  border-radius: 10px 10px 10px 150px;
}
.ajv-section-right .benefits-section-5 {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  padding: 20px;
  border-radius: 150px 10px 10px 150px;
}
.ajv-section-right .benefits-section-6 {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  padding: 20px;
  border-radius: 150px 10px 10px 10px;
}
.ajv-section-middle {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  border-radius: 50%;
}
.gradient-text {
  background: linear-gradient(to right, red, black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-weight: bold;
}
.benefits-section-4 p,
.benefits-section-5 p,
.benefits-section-6 p {
  padding: 10px 0px 10px 60px;
}
.benefits-section-4 h1,
.benefits-section-5 h1,
.benefits-section-6 h1 {
  padding: 0 0 0 60px;
}

/* .form-container-bg-image {
  background-image: url("./img/wpmantinance/Groupbg.png");
} */

.custom-section {
  position: relative;
  width: 100%;
  height: 744px; /* Matches the SVG height */
  background: #ecf3ff; /* Matches the SVG fill color */
  clip-path: path(
    "M-261 80.6162C-261 32.8518 -219.416 -4.2697 -171.958 1.12887L635.042 92.9282C675.465 97.5266 706 131.731 706 172.416V555.035C706 595.098 676.365 628.984 636.659 634.322L-170.341 742.812C-218.327 749.263 -261 711.943 -261 663.525V80.6162Z"
  );
}

.custom-section .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #003366; /* Adjust as needed */
}

.custom-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.custom-section p {
  font-size: 1.2rem;
}
.items-processe-section-1 .animation-circle {
  position: absolute;
  top: -9px;
  right: 23px;
}

.animation-circle {
  /* border: 2px dashed #6a6a6a; */
  border-radius: 50%;
  padding: 7px;
  border: 3px dotted #6a6a6a;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 185px;
  border-radius: 50%;
  animation: rotateCircle 10s linear infinite; /* Animation only applied to this element */
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure child elements remain static */
.outer-circle,
.inner-circle,
svg {
  animation: none; /* No animation applied */
}
.items-processe-section-5 .animation-circle {
  position: absolute;
  top: -13px;
  left: 22px;
  right: 0px;
}

.items-processe-section-2 .animation-circle {
  position: absolute;
  top: -11px;
  right: 7px;
}
.items-processe-section-3 .animation-circle {
  position: absolute;
  top: -14px;
  right: 8px;
}
.items-processe-section-4 .animation-circle {
  position: absolute;
  top: -9px;
  right: 8px;
}
.items-processe-section-2 {
  margin-top: 20px;
}

.items-processe-section-3 {
  margin-top: 20px;
}

.items-processe-section-4 {
  margin-top: 20px;
}

.footer-section .Risk-Websites p {
  font-size: 25px;
  font-weight: 500;
  line-height: 41px;
}
.footer-section .Risk-Websites button {
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  background: linear-gradient(90deg, #ee1021 0%, #880913 100%);
  border: none;
  border-radius: 8px; /* Optional: Add rounded corners */
  padding: 15px 50px; /* Optional: Adjust padding for better spacing */
  animation: pulse 2s ease-in-out infinite; /* Add pulsing animation */
  transition: transform 0.3s ease; /* Smooth scaling transition */
}

.custom-shape {
  width: 752px;
  height: 739px;
  background-color: #fff5e1; /* Matches the fill color of the SVG */
  clip-path: path(
    "M-51.2843 50.7686C-51.664 20.649 -25.5221 -2.9659 4.404 0.463326L702.654 80.4757C727.703 83.3461 746.697 104.419 746.959 129.631L749.25 350L751.555 571.729C751.811 596.352 734.1 617.496 709.813 621.562L15.0161 737.872C-15.2197 742.933 -42.8487 719.842 -43.2352 689.188L-51.2843 50.7686Z"
  );
  position: absolute;
  top: 0;
  z-index: -1;
}

.main-wordpess-section-headding {
  font-size: 43px;
  font-weight: 700;
  padding-bottom: 10px;
}
.selectedplan input {
  color: #000;
}
.basic-plan:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.Get-Free-Consultation-button {
  border-radius: 44px;
  background: linear-gradient(90deg, #ee1021 0%, #880913 100%);
  font-size: 20px;
  padding: 15px 40px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  font-weight: 500;
  color: #fff;
  /* transition: all 0.3s ease;
  animation: pulse 2s ease-in-out infinite; */
}

.circle-posotion {
  position: relative;
  z-index: 111;
}
.card-heading {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: -5%;
}
.main-wordpess-section {
  background-image: url("./img/wpmantinance/pricingbg.png");
  /* height: 100vh; */
}
.main-wordpess-section-thankyou {
  background-image: url("./img/wpmantinance/pricingbg.png");
}
.Wordpress-section {
  font-family: Arial, sans-serif;
  background-color: #1b1b2f;
  color: #fff;
  margin: 0;
  padding: 0;
}

.basic-plan {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px dotted #000000c2;
  background: #2b263491;
  position: relative;
  margin: 20px;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.main-inners-content {
  padding: 18px;
}

.btn-plan {
  width: 100%;
  padding: 10px 20px;
}

ul.inner-description {
  margin: 0;
  padding: 0;
}
.innder-wordpress-para {
  display: flex;
  justify-content: center;
}

.basic-plan img {
  margin-top: -15px;
  /* height: 210px; */
}

.wordpress-container h1 {
  text-align: center;
}
.plancard {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.react-tel-input .form-control {
  height: 45px !important;
  width: 100% !important;
  color: #000 !important;
}
.checkoutmembership {
  color: #ee1021;
  border: 1px solid #ee1021;
  border-radius: 40px 40px 40px 0px;
  padding: 2px 12px;
}
.membershipform {
  width: 50%;
  padding: 8px 25px;
  background: #2b263491;
}
/* .membershipform label {
  color: #fff;
} */
.membershipform button {
  background: #ee1021;
  font-size: 12px;
}
.checkoutbg {
  height: 598px;
  width: 50%;
  background-color: #fff;
}
.checkoutbg img {
  height: 100%;
  width: 100%;
}
.thankyoupage label {
  color: #fff;
}

.thankyoupagemainform .wpforms {
  background: #2b263491;
}
.react-tel-input .country-list {
  color: #000;
}

.subscription-btn button {
  padding: 0px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  transition: transform 0.3s ease-in-out;
  /* text-shadow: 1px 1px #8D8D8D !important; */
  width: 87%;
  height: 50px;
}
.subscription-btn-inner {
  padding: 4px;
}
.subscription-btn-inner {
  width: 80%;
  margin: auto;
  border-radius: 40px;
}
.subscription-btn {
  position: relative;
  top: 3.5%;
}
.error {
  color: red;
}
.bgbackcolor {
  background: #fff;
  padding: 50px 20px;
  position: relative;
  z-index: 0;
}
.bgbackcolor::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #fff3f4, #fff3f4);
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 55%);
  z-index: -1; /* Place background behind the content */
}

.mainwp-popup .form-input-section textarea {
  width: 100%;
  border: 1px solid #9b9b9b;
  padding: 10px;
}
.mainwp-popup .form-input-section {
  width: 100%;
  margin: 15px 0px;
}
.mainwp-popup button.wordpress-btn {
  width: 100%;
  padding: 10px;
  background: #ee1021;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
}
.mainwp-popup .form-input-section input {
  width: 100%;
  height: 40px;
  border: 1px solid #9b9b9b;
  padding: 10px;
  border-radius: 5px;
}
.mainwp-popup form {
  box-shadow: none !important;
}
.wordpress {
  z-index: 10000;
}
.wordpress .modal-details {
  width: 950px;
  padding: unset;
  border-bottom: unset;
  background: unset;
}
.mainwp-popup h2 {
  color: #fff;
}
.mainwp-popup {
  background-image: url("../src/img/wpmantinance/wordpresspopup.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.wordpress i {
  color: #fff;
}

/* ============================================================ */
.ajv-maintenance-section {
  position: relative;
  color: #ffffff;
  overflow: hidden;
}

.sphere_section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 200px;
  pointer-events: none; /* Prevent interfering with user interaction */
  overflow: hidden;
}

.spherebubble {
  position: absolute;
  border-radius: 50%;

  /* mix-blend-mode: normal; */
  animation: float 6s infinite ease-in-out;
}

/* Sphere Specific Sizes and Positions */
.spherebubble-sphere_3 {
  width: 150px;
  height: 150px;
  top: 54%;
  left: 36%;
  box-shadow: 0px 44px 48px 0px #3d141b;
  background: linear-gradient(41.69deg, #130e1e 44.78%, #702513 84.72%);
}

.spherebubble-sphere_6 {
  width: 200px;
  height: 200px;
  bottom: 13%;
  right: 0%;
  background: linear-gradient(-120deg, #7b1900 0%, #170d2a 54%, #170d2a 100%);
  box-shadow: 0 2px 100px 0px #651c16;
}

.spherebubble-sphere_7 {
  width: 250px;
  height: 250px;
  top: 30%;
  right: 35%;
  background: linear-gradient(41.69deg, #130e1e 44.78%, #702513 84.72%);
  box-shadow: 0px 13px 22px 0px #3d141b;
}

.spherebubble-sphere_8 {
  width: 100px;
  height: 100px;
  bottom: 5%;
  left: 10%;
  background: linear-gradient(-120deg, #7b1900 0%, #170d2a 54%, #170d2a 100%);
  box-shadow: 0 2px 100px 0px #651c16;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
/* ============================================================================================= */

/* Floating Keyframe Animations */

@keyframes float_s1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust for floating effect */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float_s2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust for floating effect */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float_s3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust for floating effect */
  }
  100% {
    transform: translateY(0);
  }
}

.membership-line-1 {
  width: 100px;
  height: 2px;
  border-bottom: 2px dashed;
}
.membership-straight-line-1 {
  width: 2px;
  border-right: 2px dashed;
  height: 550px;
}
.membership-line-main-section {
  flex-flow: column;
  display: flex;
  justify-content: space-between;
}

.membership-image-top img,
.membership-image-mid img,
.membership-image-bottom img {
  object-fit: contain;
}
.ajv-maintenance-pages button,
.ajv-maintenance-pages button {
  text-transform: uppercase;
}
.maintenance-grid {
  font-size: 22px;
  font-weight: 400;
}
.mostPopularplain {
  background: #fce100;
  color: #000;
  top: -14px;
  font-weight: 500;
  border-radius: 40px;
  z-index: 11;
}

@keyframes expandLeftToRight {
  from {
    width: 0%;
  }
  to {
    width: 20%;
  }
}

.WpmantinanceLogo-style {
  padding: 25px 0px;
  position: absolute;
  width: 13%;
  height: 175px;
}

.WpmantinanceLogo-style-inner {
  background-color: #fff;
  display: flex;
  justify-content: end;
  padding: 0% 20%;
  height: 100%;
  overflow: hidden;
  transition: width 0.5s ease-out;
  border-radius: 0px 75px 75px 0px;
}

.WpmantinanceLogo-style-inner img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.expand {
  animation: expandLeftToRight 0.5s ease-out forwards;
}
.page-template-margin-top {
  padding-top: 10%;
}

.hecked_section_background-inner {
  background: #00264580;
  border-radius: 20px 20px 20px 20px;
  opacity: 0.9;
}
.animation-circle-heading {
  font-size: 25px;
  font-weight: 700;
  color: #feb155;
}
.maintenance-grid p {
  font-weight: 500;
  font-size: 20px;
}

.Membership-chackout h1 {
  font-size: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedplan {
  border-color: #2b263491;
  background: #2b263491;
  color: #fff;
}
.subscription-page {
  background-color: #000;
  position: relative;
}
.subscription-page-inner {
  position: relative;
  z-index: 99;
}
.description-inner {
  border-bottom: 1px dashed #4d4d4d;
  padding: 7px 0px;
  gap: 10px;
  font-size: 14px;
}
.card-heading h2 {
  font-size: 35px;
  font-weight: 600;
  text-shadow: 0px 4px 4px 0px #00000040;
}

.animation-circle-Prevents {
  font-size: 25px;
  font-weight: 700;
  color: #b865d8;
}

.animation-circle-blue {
  font-size: 25px;
  font-weight: 700;
  color: #2f39bf;
}
.animation-circle-yellow {
  font-size: 25px;
  font-weight: 700;
  color: #fbd808;
}
.animation-circle-teal {
  font-size: 25px;
  font-weight: 700;
  color: #09ebaf;
}

.thankyou-WpmantinanceLogo-style,
.checkout-WpmantinanceLogo-style,
.WpmantinanceLogo-style {
  opacity: 0;
  transform: translateX(-50%);
  transition:
    opacity 0.6s ease-out,
    transform 0.6s ease-out;
}
.thankyou-WpmantinanceLogo-style.animate-slide,
.checkout-WpmantinanceLogo-style.animate-slide,
.WpmantinanceLogo-style.animate-slide {
  opacity: 1;
  transform: translateX(0);
}

/* For logo */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* For text */
@keyframes flipInRight {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

/* For form */
@keyframes flipInLeft {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

/* Trigger animations */
.logo-animate {
  animation: slideInFromLeft 0.6s ease-out forwards;
}

.text-animate {
  animation: flipInRight 0.6s ease-out forwards;
}

.form-animate {
  animation: flipInLeft 0.6s ease-out forwards;
}
.wp-Edit-btn button {
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 33px;
  cursor: pointer;
}
.checkout-submit-btn button {
  font-size: 20px;
  font-weight: 700;
  border-radius: 33px;
  background: linear-gradient(90deg, #ee1021 0%, #ec0f20 100%);
}
.input-form-style .react-tel-input .form-control {
  padding-left: 13% !important;
}
.input-form-style .react-tel-input .form-control {
  border-radius: 10px;
}
.input-form-style .react-tel-input .flag-dropdown {
  border-radius: 10px 0 0 10px;
}
.input-form-style input {
  height: 45px !important;
}
.secure-websitesection p {
  font-size: 22px;
  font-weight: 600;
}
.secure-websitesection span {
  text-shadow:
    1px 1px 2px #000000,
    0 0 1em #000000,
    0 0 0.2em #1c1717;
}
.thankyou-WpmantinanceLogo-style {
  padding: 25px 0px;
  position: absolute;
  width: 13%;
  height: 175px;
  left: 0px;
}

.thankyou-WpmantinanceLogo-style-inner {
  background-color: #fff;
  display: flex;
  justify-content: end;
  padding: 0% 20%;
  height: 100%;
  overflow: hidden;
  transition: width 0.5s ease-out;
  border-radius: 0px 75px 75px 0px;
}

.thankyou-WpmantinanceLogo-style-inner img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
.ajv-maintenance-pages .thankyou-WpmantinanceLogo-style {
  display: none;
}

.checkout-WpmantinanceLogo-style {
  padding: 25px 0px;
  position: absolute;
  width: 13%;
  height: 175px;
  left: 0px;
}
.subscription-WpmantinanceLogo-style {
  padding: 25px 0px;
  position: absolute;
  width: 13%;
  height: 175px;
  left: 0px;
}

.card-heading select {
  color: #ffffff;
  font-size: 14px;
  border: none;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  /* border-radius: 3px; */
  width: 100px;
  background: no-repeat;
  border-bottom: 2px dashed;
}

/* ================================================1/3/25============================================================================== */
/* ===================================================responcive css for Website Maintenance Services======================================================== */

/* Extra Small Screens: Up to 639px */
@media (max-width: 500px) {
  .animated_image_section {
    top: 25%;
    height: 250px !important;
    width: 250px !important;
  }
  .basic-plan {
    margin: 40px 5%;
  }
  .ajv-maintenance-pages .What-You-Missing-section button {
    font-size: 17px;
    padding: 15px 40px;
  }
  .WpmantinanceLogo-style {
    width: 25% !important;
    height: 110px !important;
    top: -1% !important;
  }
  .checkout-submit-btn button {
    width: 100%;
  }
  .input-form-style .react-tel-input .form-control {
    padding-left: 15% !important;
  }
  .Membership-chackout h1 {
    font-size: 24px !important;
  }
  .maintenance-grid p {
    font-size: 16px !important;
  }
  .dont-wait-section button {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    padding: 10px 17px !important;
  }
  .main-wordpess-section-headding {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 22px !important;
  }

  .performing-inner-section h1 {
    font-size: 30px;
    width: 100%;
  }
  .disaster-btn {
    display: flex;
    justify-content: center;
  }
  .Until-section .dont-wait-section p {
    font-size: 20px !important;
    line-height: 40px !important;
  }
  .Until-section .dont-wait-section h2 {
    /* font-size: 22px !important; */
    /* font-weight: 600 !important; */
    /* text-align: center; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .Behind-section-inner h2 {
    font-size: 37px;
    line-height: 65px;
  }
  .What-You-Missing-heading {
    font-size: 40px;
    text-align: center;
  }
  .ajv-maintenance-frustrated p {
    font-size: 15px;
  }
  .react-tel-input .form-control {
    padding-left: 12% !important;
  }

  .Behind-section-inner p {
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
  }
  .form-container h2 {
    font-size: 23px;
  }
  .ajv-maintenance-frustrated button {
    padding: 12px 22px;
    font-size: 15px;
  }
  .maintenance-left {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
  }

  .ajv-maintenance-page .What-You-Missing-section button {
    font-size: 15px;
    padding: 15px 45px;
  }

  .page-template-page-tldr_preview-inner {
    padding-top: 15%;
  }

  .membership-image-top,
  .membership-image-bottom {
    width: 75%;
  }
  .What-You-Missing-section p {
    width: 90%;
    font-size: 18px;
    font-weight: 400;
  }
  .sphere_section {
    display: none;
  }
  .custom-shape {
    display: none;
  }
  .form-container form {
    width: 100%;
  }
  .main-wordpess-section-headding {
    font-size: 17px;
    line-height: 40px;
  }
  .Get-Free-Consultation-button {
    font-size: 14px;
  }
  .membership-benefits-section .membership-benefits-heading h2 {
    font-size: 27px;
    line-height: 49px;
  }
  .footer-section .Risk-Websites p {
    font-size: 14px;
    line-height: 33px;
  }
  .percentage-content-details {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .hacked-inner-section .percentage-content {
    font-size: 18px !important;
    line-height: 35px !important;
  }
  .hacked-inner-section h2 span {
    font-size: 32px !important;
    line-height: 20px !important;
  }
  .wp-Edit-btn {
    display: flex;
    justify-content: end;
  }
}
@media (max-width: 780px) {
  .react-tel-input .form-control {
    padding-left: 13% !important;
  }

  .thankyou-WpmantinanceLogo-style {
    width: 18%;
    height: 135px;
  }
  .mostPopularplain {
    top: 0px;
  }
  .sphere_section {
    display: none;
  }
  .card-heading {
    top: 10px;
  }
  .card-heading h2 {
    font-size: 29px;
  }
  .WpmantinanceLogo-style {
    width: 15%;
    height: 125px;
    top: 5%;
  }
  .Maintenance-Services {
    font-size: 2rem;
  }
  .Membership-chackout h1 {
    font-size: 43px;
  }
  .checkout-submit-btn button {
    width: 100%;
  }
  .maintenance-left {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
  }
  .membership-benefits-section .membership-benefits-heading p {
    font-size: 20px;
    line-height: 33px;
  }
  .wp-Edit-btn {
    display: flex;
    justify-content: end;
  }
  .benefits-list-section li p {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
  }
  .animated_image_section {
    top: 25%;
    height: 210px !important;
    width: 210px !important;
  }
  .membership-image-mid img {
    display: none;
  }
  .membership-image-top,
  .membership-image-bottom {
    width: 75%;
  }

  .ajv-maintenance-frustrated button {
    padding: 12px 22px;
    font-size: 15px;
  }
  .performing-inner-section h1 {
    line-height: 50px;
    font-size: 28px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .membership-benefits-section .membership-benefits-heading h2 {
    line-height: 50px;
    font-size: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-section .Risk-Websites button {
    font-size: 18px;
    line-height: 26px;
    padding: 15px 35px;
  }
  .custom-shape {
    display: none;
  }

  .footer-section .Risk-Websites p {
    font-size: 18px;
    line-height: 33px;
  }
  .Get-Free-Consultation-button {
    font-size: 14px;
  }
  .image-membership-outer-section {
    justify-content: center;
  }
  .animated_image_section {
    top: 21%;
    border: 1.8px dashed #6a6a6ad1;
    border-radius: 50%;
    background-color: #fbfbfb63;
    height: 350px;
    width: 350px;
    right: -20px;
    bottom: 0px;
    display: flex;
    left: 47%;
  }
  .hacked-inner-section {
    margin-right: 0px;
  }
  .behind-icon-section {
    height: 72px;
    width: 72px;
    padding: 15px;
  }
  .Excellence-Experience {
    font-size: 10px;
    line-height: 10px;
    padding: 2px 7px;
    left: 25px;
    top: -11px;
  }
  .performing-section-details button {
    font-size: 16px;
    line-height: 23px;
    padding: 15px 23px;
  }

  .items-processe-section-5 .inner-circle,
  .items-processe-section-4 .inner-circle,
  .items-processe-section-2 .inner-circle,
  .items-processe-section-3 .inner-circle,
  .items-processe-section-1 .inner-circle {
    padding: 15px;
  }
  .animation-circle {
    width: 110px;
    height: 110px;
  }

  .hacked-inner-section .percentage {
    font-size: 84px;
    line-height: 95px;
  }

  .maintenance-grid p {
    font-size: 13px;
  }
  .hacked-inner-section .percentage-content {
    font-size: 26px;
    line-height: 40px;
  }
  .hacked-inner-section h2 span {
    font-size: 45px;
    line-height: 28px;
  }
  .percentage-content-details {
    font-size: 24px;
    line-height: 27px;
  }
  .hacked-inner-section {
    margin-right: 0px;
  }

  .Until-section .dont-wait-section h2 {
    font-size: 30px;
    font-weight: 500;
    /* line-height: 35px; */
    color: #343434;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .disaster-btn {
    display: flex;
    justify-content: center;
  }
  .dont-wait-section button {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  .Until-section .dont-wait-section p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  .performing-section-details p {
    font-size: 16px;
    line-height: 27px;
  }

  .items-processe-section-5 .animation-circle {
    position: absolute;
    top: 3px;
    left: 1px;
    right: 0px;
  }
  .items-processe-section-1 .animation-circle {
    position: absolute;
    top: 8px;
    right: 0px;
  }
  .your-wordpress-website {
    display: none;
  }
  .items-processe-section {
    width: 100%;
  }
  .image-container-inner {
    height: 178px;
  }

  .main-wordpess-section-headding {
    font-size: 26px;
    text-align: center;
    line-height: 53px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .checkoutbg {
    width: 100%;
    height: auto;
  }
  .main-from-section-style .membershipform {
    width: 100%;
  }
  .checkout-WpmantinanceLogo-style {
    width: 20%;
    height: 125px;
  }
}
@media (min-width: 780px) and (max-width: 1024px) {
  .checkoutbg {
    width: 100%;
    height: auto;
  }
  .card-heading {
    top: 2%;
  }

  .card-heading h2 {
    font-size: 22px;
  }
  .WpmantinanceLogo-style {
    width: 20%;
    height: 135px;
  }
  .main-from-section-style .membershipform {
    width: 100%;
  }
  .checkout-submit-btn button {
    width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 1300px) {
  .checkoutbg {
    height: auto;
  }
}
/* Small Screens: 640px to 767px */
@media (min-width: 500px) and (max-width: 640px) {
}

/* Small Screens: 640px to 767px */
@media (min-width: 640px) and (max-width: 1020px) {
  .page-template-page-tldr_preview .sphere_8 {
    left: 0%;
  }
  button.wordpress-btn {
    padding: 10px;
    font-size: 16px;
  }
  .input-form-style .react-tel-input .form-control {
    padding-left: 8% !important;
  }
  .membership-benefits-section .membership-benefits-heading h2 {
    line-height: 50px;
    font-size: 30px;
    width: 100%;
  }

  .form-input-section input {
    height: 40px;
  }

  .your-wordpress-website-heading {
    line-height: 50px;
    font-size: 30px;
  }
  .performing-inner-section h1 {
    line-height: 50px;
    font-size: 30px;
    width: 100%;
  }
  .maintenance-left {
    font-size: 30px;
  }
  .form-container form {
    width: 100%;
  }
  .form-container h2 {
    font-size: 22px;
    /* line-height: 25px; */
  }
  .Excellence-Experience {
    font-size: 10px;
    line-height: 15px;
    padding: 0px 4px;
    left: 35px;
  }
  .ajv-maintenance-frustrated p {
    font-size: 14px;
    /* line-height: 20px; */
  }

  .What-You-Missing-heading {
    /* line-height: 50px; */
    font-size: 30px;
  }
  .ajv-maintenance-pages .What-You-Missing-section button {
    font-size: 16px;
    padding: 15px 45px;
  }
  .What-You-Missing-section p {
    width: 80%;
    font-size: 18px;
    line-height: 25px;
  }
  .ajv-maintenance-page .What-You-Missing-section button {
    font-size: 14px;
    padding: 13px 40px;
    font-weight: 500;
  }
  .Behind-section-inner h2 {
    line-height: 50px;
    font-size: 30px;
  }
  .Behind-section-inner p {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }

  .ajv-maintenance-frustrated button {
    padding: 10px 45px;
    font-size: 16px;
  }
}

/* Large Screens: 1024px to 1279px */
@media (min-width: 1024px) and (max-width: 1279px) {
  .Behind-section-inner {
    text-align: center;
  }
  .card-heading {
    top: 2%;
  }

  .card-heading h2 {
    font-size: 22px;
  }
  .WpmantinanceLogo-style {
    width: 16%;
    height: 140px;
  }
  .footer-section .Risk-Websites p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  .Behind-section-inner p {
    font-size: 16px;
  }
  .Get-Free-Consultation-button {
    font-size: 14px;
    padding: 15px 30px;
  }

  .animated_image_section {
    top: 18%;
    height: 270px;
    width: 270px;
    right: 5%;
  }
  .membership-image-top {
    width: 60%;
  }

  .membership-image-bottom {
    width: 60%;
  }
  .footer-section .Risk-Websites button {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 15px 30px;
  }

  .behind-icon-section {
    height: 74px;
    width: 75px;
    padding: 20px;
  }

  .membership-image-mid {
    height: 150px;
    width: 125px;
  }
  .main-wordpess-section-headding {
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 20px;
    text-align: center;
  }

  .animation-circle {
    width: 160px;
    height: 160px;
  }
  .inner-circle {
    padding: 28px !important;
  }
  .items-processe-section-5 {
    top: 58px;
    right: 4%;
  }
  .items-processe-section-1 {
    left: 3%;
  }
  .Excellence-Experience {
    font-size: 12px;
    line-height: 15px;
    padding: 2px 7px;
    left: 25px;
    top: -11px;
  }
  .basic-plan {
    width: 290px;
  }
  .membership-benefits-section .membership-benefits-heading h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .items-processe-section-5 .animation-circle {
    left: -4px;
    top: -7px;
  }
  .items-processe-section-1 .animation-circle {
    right: -5px;
    top: -4px;
  }
  .items-processe-section-2 .animation-circle,
  .items-processe-section-3 .animation-circle {
    right: 13px;
    top: -8px;
  }
  .items-processe-section {
    width: 30%;
  }

  .items-processe-section-4 .animation-circle {
    right: 13px;
    top: -3px;
  }
  .your-wordpress-website-heading {
    font-size: 35px;
    line-height: 51px;
  }
  .dont-wait-section button {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }
  .Until-section .dont-wait-section p {
    font-size: 20px;
    line-height: 35px;
  }
  .Until-section .dont-wait-section h2 {
    font-size: 30px;
    line-height: 35px;
  }
  button.wordpress-btn {
    padding: 12px;
    font-size: 16px;
  }
  .hacked-inner-section h2 span {
    font-size: 40px;
    line-height: 33px;
  }
  .hacked-inner-section .percentage-content {
    font-size: 20px;
    line-height: 30px;
  }
  .percentage-content-details {
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
  }
  .hacked-inner-section .percentage {
    font-size: 85px;
    line-height: 80px;
  }
  .maintenance-left {
    font-size: 30px;
  }
  .page-template-page-tldr_preview .sphere_8 {
    left: 20%;
  }
  .ajv-maintenance-frustrated p {
    font-size: 14px;
    /* line-height: 20px; */
  }
  .form-container h2 {
    font-size: 22px;
    /* line-height: 33px; */
  }
  .ajv-maintenance-frustrated button {
    font-size: 15px;
    padding: 14px 45px;
  }
  .form-container form {
    width: 100%;
    margin-right: 0px;
  }
  .What-You-Missing-heading {
    font-size: 30px;
    font-weight: 500;
    /* line-height: 35px; */
    color: #343434;
  }
  .What-You-Missing-section p {
    width: 80%;
    font-size: 15px;
    line-height: 25px;
  }
  .ajv-maintenance-page .What-You-Missing-section button {
    font-size: 14px;
    padding: 13px 40px;
    font-weight: 500;
  }
  .Behind-section-inner h2 {
    line-height: 50px;
    font-size: 30px;
    text-align: center;
  }
  /* .Behind-section-inner p {
    font-size: 20px;
    text-align: center;
  } */
  .performing-inner-section h1 {
    width: 90%;
    font-size: 30px;
    line-height: 35px;
  }
  .performing-section-details p {
    font-size: 16px;
    line-height: 28px;
  }
  .performing-section-details button {
    background: linear-gradient(90deg, #ee1021 0%, #880913 100%);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #fff;
    padding: 14px 18px;
    border-radius: 10px;
  }
  .wordpress-processe-section {
    width: 60%;
  }
  .description-inner p {
    font-size: 14px;
  }
}

/* Extra Large Screens: 1280px and up */

@media (min-width: 1280px) and (max-width: 1500px) {
  .maintenance-left {
    font-size: 45px;
  }
  .card-heading {
    top: 2%;
  }

  .card-heading h2 {
    font-size: 24px;
  }
  .WpmantinanceLogo-style {
    width: 15%;
    height: 155px;
  }
  .hacked-inner-section .percentage-content {
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
  }
  .percentage-content-details {
    font-size: 21px;
    line-height: 30px;
  }
  .hacked-inner-section h2 span {
    font-size: 42px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
  }

  .items-processe-section-1 .animation-circle {
    right: 5px;
  }
  .items-processe-section-5 .animation-circle {
    left: 5px;
  }
  .items-processe-section-2 .animation-circle {
    right: -10px;
  }
  .items-processe-section-3 .animation-circle {
    right: -10px;
  }
  .items-processe-section-4 .animation-circle {
    right: -8px;
  }

  .Behind-section-inner h2 {
    font-size: 45px;
    line-height: 40px;
  }
  .ajv-maintenance-frustrated p {
    font-size: 17px;
    font-weight: 300;
    /* line-height: 26px; */
  }
  .your-wordpress-website-heading {
    font-size: 45px;
    /* line-height: 40px; */
  }
  .membership-benefits-section .membership-benefits-heading h2 {
    font-size: 45px;
    font-weight: 500;
    line-height: 52px;
  }
  .performing-inner-section h1 {
    font-size: 45px;
    font-weight: 500;
    line-height: 52px;
    width: 85%;
  }
  .What-You-Missing-heading {
    font-size: 45px;
    /* line-height: 40px; */
  }
  .What-You-Missing-section p {
    width: 88%;
    font-size: 16px;
    line-height: 27px;
  }
  .form-container h2 {
    font-size: 27px;
    line-height: 36px;
  }
  .form-container form {
    width: 80%;
    margin-right: 20px;
  }
  .ajv-maintenance-frustrated button {
    padding: 10px 35px;
    font-size: 14px;
  }
}

@media (min-width: 1500px) and (max-width: 1600px) {
  .items-processe-section-5 {
    position: absolute;
    top: 35px;
    /* right: 3%; */
    width: 15%;
  }
}
@media (min-width: 1280px) {
  .page-template-page-tldr_preview .sphere_8 {
    left: 50%;
  }
}
.membership-image {
  display: flex;
  align-items: center;
}
/* ===================================================responcive css for Website Maintenance Services end======================================================== */
@media (min-width: 1500px) and (max-width: 1550px) {
  .container {
    margin: auto !important; /* Example text color */
    width: 90% !important; /* Set the width to 80% */
  }
}
@media (min-width: 1500px) and (max-width: 1536px) {
  .items-processe-section-1 .animation-circle {
    top: -10px;
    right: 4px;
  }
  .items-processe-section-5 .animation-circle {
    top: -13px;
    left: 3px;
    right: 0px;
  }
  .performing-inner-section h1 {
    width: 100%;
  }
  .items-processe-section-2 .animation-circle {
    top: -11px;
    right: -8px;
  }

  .items-processe-section-4 .animation-circle {
    top: -9px;
    right: -8px;
  }
  .items-processe-section-3 .animation-circle {
    top: -14px;
    right: -10px;
  }
  .hacked-inner-section .percentage {
    font-size: 84px;
    line-height: 65px;
  }
  .hacked-inner-section .percentage-content {
    font-size: 23px;
  }
  .hacked-inner-section h2 span {
    font-size: 45px;
    line-height: 30px;
  }
  .percentage-content-details {
    font-size: 21px;
    line-height: 26px;
  }
  .maintenance-left {
    font-size: 43px;
  }
}

@media (min-width: 1537px) and (max-width: 1550px) {
  .items-processe-section-1 .animation-circle {
    top: -10px;
    right: 12px;
  }
  .items-processe-section-1 {
    left: 3%;
  }
  .items-processe-section-5 .animation-circle {
    top: -13px;
    left: 12px;
    right: 0px;
  }
  .performing-inner-section h1 {
    width: 100%;
  }
  .items-processe-section-2 .animation-circle {
    top: -11px;
    right: 0px;
  }

  .items-processe-section-4 .animation-circle {
    top: -9px;
    right: -2px;
  }
  .items-processe-section-3 .animation-circle {
    top: -14px;
    right: -1px;
  }
  .hacked-inner-section .percentage {
    font-size: 84px;
    line-height: 65px;
  }
  .hacked-inner-section .percentage-content {
    font-size: 23px;
  }
  .hacked-inner-section h2 span {
    font-size: 45px;
    line-height: 30px;
  }
  .percentage-content-details {
    font-size: 21px;
    line-height: 26px;
  }
  .maintenance-left {
    font-size: 43px;
  }
}

.container-box {
  width: 80%;
  margin: auto;
  background-color: white;
}
.of {
  font-size: 50px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #343434;
}
.secure {
  font-size: 40px;
  font-weight: 700;
  border-radius: 46.5px 46.5px 46.5px 0px;
  border: 1px 0px 0px 0px;
  margin-left: 10px;
  color: #ee1021;
}

.firstround {
  width: 90%;
  height: 150px;

  border-radius: 50%;
}
.c {
  border-radius: 91px;
  height: 150px;
  background-color: #feb155;
  width: 85%;
}
.secondround {
  background: linear-gradient(135.7deg, #fece94 19.07%, #feb155 84.16%);
  border-radius: 50%;
  margin-top: 17px;
  width: 70%;
  height: 100px;
}
.eyee {
  width: 50%;
  height: 50px;
}
.eyee1 {
  width: 100%;
  height: 80px;
}
.thirdround {
  width: 90%;
  height: 100px;

  border-radius: 50%;
}
.forthround {
  background: linear-gradient(135.7deg, #d9aeeb 19.07%, #a057bd 84.16%);

  border-radius: 50%;
  margin-top: 17px;
  width: 70%;
  height: 100px;
}
.c {
  border-radius: 91px;
  height: 133px;
  background-color: #feb155;
  width: 85%;
}
.third {
  border-radius: 91px;
  height: 133px;
  background-color: #b865d8;
  width: 85%;
}
.fforward {
  border-radius: 91px;
  height: 133px;
  background-color: #fbd808;
  width: 85%;
}
.fforwardround {
  background: linear-gradient(228.16deg, #fdeb83 15.14%, #fbd808 86.61%);

  border-radius: 50%;
  margin-top: 17px;
  width: 70%;
  height: 100px;
}

.back {
  border-radius: 91px;
  height: 133px;
  background-color: #09ebaf;
  width: 85%;
}
.backround {
  background: linear-gradient(219.45deg, #84f5d7 16.09%, #09ebaf 85.64%);

  border-radius: 50%;
  margin-top: 17px;
  width: 70%;
  height: 100px;
}
.forward {
  border-radius: 91px;
  height: 133px;
  background-color: #2f39bf;
  width: 85%;
}
.forwardround {
  background: linear-gradient(219.45deg, #979cdf 16.09%, #2f39bf 85.64%);

  border-radius: 50%;
  margin-top: 17px;
  width: 70%;
  height: 100px;
}
.number1 {
  border-radius: 2px;
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid #feb155;
}
.number2 {
  border-radius: 2px;
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid #b865d8;
}

.number3 {
  border-radius: 2px;
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid #feb155;
}

.number4 {
  border-radius: 2px;

  font-size: 25px;
  font-weight: 700;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid #fbd808;
}
.number5 {
  font-size: 25px;
  border-radius: 2px;

  font-weight: 700;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid#2F39BF;
}

.whole {
  height: 280px;
}
.main-section-wp-Securing {
  margin-top: 26%;
}
.main-box-section {
  border-radius: 5px;
  align-content: center;
  box-shadow: 0px 10px 18px 0px #feb15540;
  border-width: 1px;
  height: 230px;
  border: 1px solid #585858;
}
.same {
  padding: 15px 0;
}
.underline1 {
  border-style: solid;
  border-width: 2px;
  border-radius: 7px;
  border-color: #feb155;
  width: 100%;
  text-align: center;
}
.underline2 {
  border-style: solid;
  border-width: 2px;
  border-radius: 7px;
  border-color: #b865d8;
  width: 100%;
  text-align: center;
}
.underline3 {
  border-style: solid;
  border-width: 2px;
  border-radius: 7px;
  border-color: #fbd808;
  width: 100%;
  text-align: center;
}
.underline4 {
  border-style: solid;
  border-width: 2px;
  border-radius: 7px;
  border-color: #09ebaf;
  width: 100%;
  text-align: center;
}
.underline5 {
  border-style: solid;
  border-width: 2px;
  border-radius: 7px;
  border-color: #2f39bf;
  width: 100%;
  text-align: center;
}
.outer-section {
  background: #feb155;
  border-radius: 50%;
  padding: 18px;

  outline: #ffd8aa solid 3px;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.inner-section-crecle-website {
  background: linear-gradient(135.7deg, #fece94 19.07%, #feb155 84.16%);
  box-shadow: 3px 3px 0px 0px #00000040;
  border-radius: 50%;
  padding: 30px;
}
.oouter-section {
  background: #b865d8;
  border-radius: 50%;
  padding: 18px;
  outline: #dbb1eb solid 3px;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.iinner-section {
  background: linear-gradient(135.7deg, #d9aeeb 19.07%, #a057bd 84.16%);

  border-radius: 50%;
  padding: 30px;
  box-shadow: 3px 3px 0px 0px #00000040;
}
.mmain-section-crecle {
  display: flex;
  justify-content: center;
  position: relative;
  top: 64px;
}
.iimage-section {
  display: flex;
  justify-content: center;
}

.ooouter-section {
  background: #fbd808;
  border-radius: 50%;
  padding: 18px;
  outline: #fdeb83 solid 3px;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.iiinner-section {
  background: linear-gradient(228.16deg, #fdeb83 15.14%, #fbd808 86.61%);
  box-shadow: 3px 3px 0px 0px #00000040;
  border-radius: 50%;
  padding: 30px;
}
.mmmain-section-crecle {
  display: flex;
  justify-content: center;
  position: relative;
  top: 64px;
}
.iiimage-section {
  display: flex;
  justify-content: center;
}

.image-section {
  display: flex;
  justify-content: center;
}

.main-section-crecle {
  display: flex;
  justify-content: center;
  position: relative;
  top: 64px;
}
.immage-section {
  display: flex;
  justify-content: center;
}

.maain-section-crecle {
  display: flex;
  justify-content: center;
  position: relative;
  top: 64px;
}
.ouuter-section {
  background: #09ebaf;
  border-radius: 50%;
  padding: 18px;
  outline: #7df5d5 solid 3px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.innner-section {
  background: linear-gradient(219.45deg, #84f5d7 16.09%, #09ebaf 85.64%);
  box-shadow: 3px 3px 0px 0px #00000040;
  border-radius: 50%;
  padding: 30px;
}
.imagee-section {
  display: flex;
  justify-content: center;
}

.mainn-section-crecle {
  display: flex;
  justify-content: center;
  position: relative;
  top: 64px;
}
.outerr-section {
  background: #2f39bf;
  border-radius: 50%;
  padding: 18px;
  outline: #979cdf solid 3px;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.innerr-section {
  background: linear-gradient(219.45deg, #979cdf 16.09%, #2f39bf 85.64%);
  box-shadow: 3px 3px 0px 0px #00000040;
  border-radius: 50%;
  padding: 30px;
}
.container-box {
  font-style: poppins;
}
.section-heading-tittle {
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 3%;
}

.main {
  background-color: white;
}

.frequency-dropdown,
.website-dropdown {
  background: transparent;
  border: unset;
  cursor: pointer;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
  /* margin-top: 10px; */
}
.custom-dropdown button {
  background: #333;
  border: 1px solid #444;
  /* padding: 5px 10px; */
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.custom-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #2b263491; /* Dropdown background */
  border: 1px solid #2b263491; /* Border to match plan's theme */
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
  width: 100px; /* Full width of the parent dropdown */
  text-align: center;
  border-radius: 5px;
}
.custom-dropdown:hover .dropdown-menu {
  display: block;
}
.custom-dropdown .dropdown-menu li {
  /* padding: 5px; */
  cursor: pointer;
  font-size: 12px;
}
.custom-dropdown .dropdown-menu li:hover {
  background: red;
}
.RoadmapSpinnerDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}
.RoadmapSpinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #ee1e25;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
}
.RoadmapSpinner::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #112697;
  border-left: 4px solid transparent;
}
/* Login form label animation */
.animated-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
form.register-form-section {
  padding: 30px 0;
}

.animated-form .form-group input,
.animated-form .form-group select {
  width: 100%;
  padding: 13px 20px;
  font-size: 14px;
  border: 1px solid #000;
  border-radius: 0px;
  outline: none;
  transition: border-color 0.2s;
  background: linear-gradient(90deg, #00214e 0%, #065e9c 100%);
  border: 0.2px solid #00204e;
  box-shadow: 0px 1px 5px 0px #00000040;
}

.animated-form .form-group input:focus,
.animated-form .form-group select:focus {
  border-color: #29dbc2;
}

.animated-form .form-group label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 0 5px;
  transition: 0.2s ease-in-out;
  color: #fff;
  pointer-events: none;
}
.animated-form .form-group input:focus + label,
.animated-form .form-group input:not(:placeholder-shown) + label,
.animated-form .form-group select:focus + label,
.animated-form .form-group select:not([value=""]) + label {
  top: 0px;
  left: 16px;
  font-size: 12px;
}

.animated-form .form-group input:not(:placeholder-shown) + label,
.animated-form .form-group select:not([value=""]) + label {
  font-size: 12px;
}
.register-form-section .form-group label {
  top: 50%;
}
.animated-form textarea {
  font-size: 13px;
  color: #555;
  outline: none;
  border: 1px solid #036;
  padding: 10px 20px;
  border-radius: 8px;
  position: relative;
  display: block;
}

textarea:invalid + label {
  transform: translateY(0);
}

textarea:focus {
  border-color: #29dbc2;
}

textarea:focus + label {
  transform: translateY(-20px);
  top: 0px !important;
}
/* End */
.dropdown-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  margin: 0 0px 2px 5px; /* Arrow color */
}
.main.box.website-inner-section {
  padding: 30px 0;
}
.mainwp-popup .headingform {
  display: none;
}
.ajivaloginpage .ajv-maintenance-page-inner {
  /* background-image: url("./img/wpmantinance/Groupbg.png"); */
  /* background-color: #0a2a4a; */
  background: linear-gradient(
    180deg,
    #062448 0%,
    #11334c 31.28%,
    #0e2338 73.19%,
    #7c8793 100%
  );

  position: relative;
  /* z-index: -11111111111; */
}
.ajivaloginpage .spherebubble {
  position: absolute;
  border-radius: 50%;

  /* mix-blend-mode: normal; */
  animation: float 6s infinite ease-in-out;
}

/* Sphere Specific Sizes and Positions */
.ajivaloginpage .spherebubble-sphere_3 {
  width: 150px;
  height: 150px;
  top: 54%;
  left: 36%;
  box-shadow: 0px 44px 48px 0px #1c4461;
  background: linear-gradient(41.69deg, #1c4461 44.78%, #1c4461 84.72%);
}

.ajivaloginpage .spherebubble-sphere_6 {
  width: 200px;
  height: 200px;
  bottom: 13%;
  right: 0%;
  background: linear-gradient(-120deg, #1c4461 0%, #1c4461 54%, #1c4461 100%);
  box-shadow: 0 2px 100px 0px #1c4461;
}

.ajivaloginpage .spherebubble-sphere_7 {
  width: 250px;
  height: 250px;
  top: 30%;
  right: 35%;
  background: linear-gradient(41.69deg, #1c4461 44.78%, #1c4461 84.72%);
  box-shadow: 0px 13px 22px 0px #1c4461;
}

.ajivaloginpage .spherebubble-sphere_8 {
  width: 100px;
  height: 100px;
  bottom: 5%;
  left: 10%;
  background: linear-gradient(-120deg, #1c4461 0%, #1c4461 54%, #1c4461 100%);
  box-shadow: 0 2px 100px 0px #1c4461;
}
.ajivaloginpage .checkoutmembership {
  color: #8cfcfe;
  border: 1px solid #8cfcfe;
  border-radius: 40px 40px 40px 0px;
  padding: 2px 12px;
}

.ajivaloginpage .spherebubble-sphere_1 {
  width: 150px;
  height: 150px;
  top: 24%;
  left: 13%;
  box-shadow: 0px 44px 48px 0px #1c4461;
  background: linear-gradient(41.69deg, #1a5074 44.78%, #1a5074 84.72%);
}
.loginform {
  background: linear-gradient(
    152.42deg,
    #195378 3.45%,
    #1a567b 32.59%,
    #1a5074 70.99%,
    #56a7d6 96.24%
  );
  border: 3px solid;
  border-image-source: linear-gradient(
    151.51deg,
    #2075ab 3.89%,
    #8dfdff 26.58%,
    #51b2d1 63.89%,
    #8dfdff 95.5%
  );
  /* border-radius: 62px; */
  border-image-slice: 1;
  box-shadow: 0px 1px 5px 0px #00000040;
}
.loginform button.login-form-btn {
  background: linear-gradient(90deg, #00214e 0%, #065e9c 100%);
  box-shadow: 0px 1px 5px 0px #00000040;
  /* border: 0.2px solid #00204e; */
  padding: 15px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease 0s !important;
}
.loginform button:hover {
  box-shadow: 0px 15px 20px rgb(36 114 195 / 40%) !important;
  transform: translateY(-7px) !important;
}
.What-You-Missing-section {
  opacity: 0;
  transform: translateY(50px);
  transition:
    opacity 1s ease,
    transform 1s ease;
}

.offer-section {
  position: absolute;
  top: -40px;
  right: -55px;
  color: #000;
  display: flex;
  align-items: center;
}
/* Discount Section Styles */
.discount-section {
  background-color: #fce100;
  padding: 0px 10px;
  border-radius: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  /* width: 170px; */
}

.discount-section-inner {
  display: flex;
  gap: 8px;
  align-items: end;
  justify-content: center;
}

.discount-outer-section {
  display: flex;
  /* position: absolute;
  right: -40px;
  top: 14px; */
}
.original-price {
  font-size: 16px;
  color: #000000;
  font-weight: 300;
}

.original-price strong {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  text-decoration-color: #060606;
}

.discount-amount {
  font-size: 16px;
  color: #000;
}

.discount-value {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}

/* Animation when element is visible */
.animate-fade-up {
  opacity: 1;
  transform: translateY(0);
}
/* .hrp-min-h-screen {
  min-height: 100vh;
} */
.wordpress-mnt-data {
  width: 80%;
  margin: auto;
}
.wordpress-mnt-importance {
  justify-content: center;
  display: flex;
}
.wordpress-mnt-the {
  font-size: 50px;
  font-weight: 700;
  color: #343434;
  text-align: center;

  margin: 0;
}
.wordpress-mnt-secure {
  font-size: 40px;
  font-weight: 700;
  border-radius: 46.5px 46.5px 46.5px 0px;
  border: 1px 0px 0px 0px;
  margin-left: 10px;
  border: solid;
  color: red;
}
.protect {
  gap: 4%;
  margin-top: 2%;
  display: flex;
}
.prevents {
  gap: 4%;
  margin-top: 2%;
  display: flex;
}
.maintain {
  gap: 4%;
  margin-top: 2%;
  display: flex;
}
.enhance {
  gap: 4%;
  margin-top: 2%;
  display: flex;
}
.support {
  gap: 4%;
  margin-top: 2%;
  display: flex;
}

.logo {
  text-align: end;
  width: 25%;
}
.sensitive {
  color: white;
  border-radius: 10px;
  font-size: 35px;
  font-weight: 700;

  text-align: center;
  border: 1px solid #ffffff;
  width: 65%;
  padding: 0px 23px;
  background-color: #c06900;
  text-shadow: 2px 2px 5px #00000040;
}
.website {
  border-radius: 10px;
  color: white;
  font-size: 35px;
  font-weight: 700;

  text-align: center;
  width: 65%;
  padding: 0px 23px;
  border: 1px solid #ffffff;
  background-color: #9200cb;
  text-shadow: 0px 2px 0px 2px red;
  text-shadow: 2px 2px 5px #00000040;
}

.functionality {
  border-radius: 10px;
  color: white;
  font-size: 35px;
  font-weight: 700;
  padding: 0px 23px;

  text-align: center;
  width: 65%;
  border: 1px solid #ffffff;
  background-color: #d9ba00;
  text-shadow: 2px 2px 5px #00000040;
}
.reputation {
  border-radius: 10px;
  color: white;
  padding: 0px 23px;
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  width: 65%;
  border: 1px solid #ffffff;
  background-color: #00bc8a;
  text-shadow: 2px 2px 5px #00000040;
}
.complaince {
  border-radius: 10px;
  color: white;
  font-size: 35px;
  font-weight: 700;
  padding: 0px 23px;
  text-align: center;
  width: 65%;
  border: 1px solid #ffffff;
  background-color: #2f39bf;
  text-shadow: 2px 2px 5px #00000040;
}
.digit {
  color: white;
  opacity: 0.1;
  font-size: 83px;
  font-weight: 500;
  text-align: center;
}

.digits {
  color: white;
  opacity: 0.1;
  font-size: 83px;
  font-weight: 500;
  text-align: center;
}
.digitt {
  color: white;
  opacity: 0.1;
  right: 27px;
  font-size: 83px;
  font-weight: 500;
  text-align: center;
}
p.written {
  margin-left: -18px;
  margin-top: 38px;
}
@media (min-width: 120px) and (max-width: 600px) {
  .building-style {
    width: 100%;
  }
  .building-form-style {
    width: 100%;
  }
  .commerce-website h2 {
    font-size: 21px;
  }

  .ecommerce-heading h1 {
    font-size: 25px;
    padding-top: 20px;
  }
  .ecommerce-heading h2 {
    font-size: 22px;
    padding: 0px 0px 15px 0px;
  }
  .Ecommerce-bg-image .building-style,
  .Ecommerce-bg-image .building-form-style {
    padding: 0px 20px;
  }
  .choosing-section .ajv-Platformspecific-inner-section {
    line-height: 35px;
  }
  .building-style p {
    font-size: 18px;
    line-height: 26px;
    margin: 20px 0px;
  }
  .building-style h2 {
    font-size: 22px;
    line-height: 25px;
  }
  .building-style h1 {
    margin-bottom: 5px;
    font-size: 21px;
    line-height: 33px;
  }
  .Platformspecific-2 {
    left: 0px;
  }
  .salesforce {
    padding: 15px;
  }

  .ajv-automation {
    left: 35px;
  }
  .salesforce svg {
    width: 42px;
    height: 42px;
  }
  .company-details h1 {
    margin-bottom: 5px;
    font-size: 18px !important;
  }
  .company-details svg {
    width: 20px;
  }
  .Ecommerce-bg-image span.phone_content {
    background: red;
    padding: 3px 15px;
    clip-path: polygon(25% 0%, 100% 0, 75% 100%, 0% 100%);
  }
  .our_services {
    width: 95% !important;
    margin: auto !important;
    margin-bottom: 15px !important;
  }
  .responcive-scr-m {
    margin: auto !important;
  }
  /* .card_hover:hover .card4 {
    margin: 0px 20px;
  } */
}
@media (min-width: 600px) and (max-width: 1000px) {
  .building-style {
    width: 100%;
  }
  .Integrationservices h2 {
    font-size: 20px;
  }
  .building-form-style {
    width: 70%;
    margin: auto;
  }
  .ecommerce-heading h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .logo-image-set {
    width: 8rem;
    margin: 0px !important;
  }
  .Ecommerce-bg-image .building-style,
  .Ecommerce-bg-image .building-form-style {
    padding: 0px 20px;
  }
  .building-style p {
    font-size: 18px;
    line-height: 26px;
    margin: 20px 0px;
  }
  .building-style h2 {
    font-size: 28px;
    line-height: 34px;
  }
  .building-style h1 {
    margin-bottom: 5px;
    /* font-size: 18px; */
  }
  .company-details h1 {
    margin-bottom: 5px;
    font-size: 18px !important;
  }
  .company-details svg {
    width: 20px;
  }
  .Ecommerce-bg-image span.phone_content {
    background: red;
    padding: 3px 15px;
    clip-path: polygon(25% 0%, 100% 0, 75% 100%, 0% 100%);
  }
  .ecommerce-heading {
    padding-top: 0px;
  }
  .icon {
    display: block;
  }
  .hideRemove {
    display: block;
    margin-top: -5px;
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
    background: #eee;
  }
  .nav_v.slide-in-right {
    display: none;
  }
  .nav_v li {
    border-bottom: 1px solid #d7d7d7;
    padding: 10px;
    width: 100%;
  }
  .hideRemove .navbaarresponcive a {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 13px;
  }
  .nav_v {
    position: absolute;
    top: 75px;
    z-index: 9;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    background: #fff;
    padding: 20px 0 20px 0;
    border-top: 3px solid #000 !important;
    border: 1px solid rgba(0, 0, 0, 0.04);
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .Integrationservices h2 {
    font-size: 20px;
  }
  .benefits-section-4 p,
  .benefits-section-5 p,
  .benefits-section-6 p {
    padding: 10px 0px 10px 20px;
  }

  .card4 {
    padding: 10px;
  }

  /* .benefits p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #6a6a6a;
    padding: 10px 0px;
    padding-right: 20px;
  } */
  .ecommerce-heading h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .building-style h2 {
    font-size: 25px !important;
    line-height: 32px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  /* .Platformspecific-3 {
    left: 0px !important;
  } */
  .Integrationservices h2 {
    font-size: 23px !important;
  }
  .benefits-section-4 p,
  .benefits-section-5 p,
  .benefits-section-6 p {
    padding: 10px 0px 10px 0px !important;
  }
  .ajv-section-right .benefits-section-4 {
    border-radius: 0px !important;
  }
  .ajv-section-right .benefits-section-5 {
    border-radius: 0px !important;
  }
  .ajv-section-right .benefits-section-6 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-2 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-1 {
    border-radius: 0px !important;
  }
  .ajv-section-left .benefits-section-3 {
    border-radius: 0px !important;
  }
  .benefits p {
    padding-right: 0px !important;
  }
  .benefits-section-4 h1,
  .benefits-section-5 h1,
  .benefits-section-6 h1 {
    padding: 0px !important;
  }
  .ajv-API-Integration-card {
    gap: 10px;
  }
  .ajv-Platformspecific img {
    height: 460px;
  }
  .building-style h1 {
    line-height: 40px;
  }
  .phone_content svg {
    height: 20px;
    width: 20px;
  }
  span.phone_content {
    padding: 2px 14px;
  }
  .ajv-enterprise {
    width: 330px;
  }
  .salesforce {
    padding: 15px;
  }
  .salesforce svg {
    width: 42px;
    height: 42px;
  }
  .ajv-automation {
    left: 38px;
  }
  .benefits p {
    padding-right: 0px !important;
    line-height: 20px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1023px) {
  .CircularProgressbar {
    width: 10% !important;
    height: 10% !important;
  }
  .our_services_card .icon div {
    display: flex;
    justify-content: center;
  }
  .icon img {
    bottom: 25px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
  .icon {
    display: block;
  }
  .hideRemove {
    display: block;
    margin-top: -5px;
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
    background: #eee;
  }
  .nav_v.slide-in-right {
    display: none;
  }
  .nav_v li {
    border-bottom: 1px solid #d7d7d7;
    padding: 10px;
    width: 100%;
  }
  .hideRemove .navbaarresponcive a {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 13px;
  }
  .nav_v {
    position: absolute;
    top: 90px;
    z-index: 9;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    background: #fff;
    padding: 20px 0 20px 0;
    border-top: 3px solid #000 !important;
    border: 1px solid rgba(0, 0, 0, 0.04);
  }
}

@media (min-width: 426px) and (max-width: 760px) {
  .modal-details {
    margin: auto;
    top: 50px;
    width: 90% !important;
  }
  .modal-details i {
    position: relative;
    top: -38px;
    right: -30px;
    background: #fff;
    border-radius: 50%;
  }

  .card_hover:hover .card4 {
    margin: 0px 20px;
  }
  .Platformspecific-1 {
    left: 0px;
  }
  .Platformspecific-3 {
    left: 0px;
  }
}
/* ============================================================================================= */
.floating-label {
  position: relative;
  display: block;
  width: 100%;
}

.floating-label input {
  width: 100%;
  padding: 12px 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s ease-in-out;
}

.floating-label label {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #777;
  background: white;
  padding: 0 4px;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.floating-label input:focus,
.floating-label input:not(:placeholder-shown) {
  /* border-color: #007bff; */
  border-radius: 5px;
}

.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 12px;
  /* color: #007bff; */
  border-radius: 5px;
}

.ajv-mtc-label {
  position: absolute;
  left: 2.5rem;
  z-index: 10;
  background-color: white;
  color: #6b7280;
  transition: all 0.3s ease-in-out;
  padding: 0 4px;
}

.ajv-mtc-focused {
  top: -10px;
  background-color: white;
  color: #6b7280;
  font-size: 12px;
  border-radius: 5px;
}

.ajv-mtc-default {
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
}
